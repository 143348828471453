<template>
  <div>
    <b-form-group
        :label-for="name+ (listIndex != -1?(' '+(listIndex+1)):'')"
        :label="displayLabel?capitalize($t(name)):''"
    >
      <validation-provider
          v-if="isRequired"
          #default="{ errors }"
          :name="$tc(name)+ (listIndex != -1?(' '+(listIndex+1)):'')"
          rules="required"
      >
        <cleave
            v-model="localModel"
            class="form-control"
            :options="maskOptions"
            :placeholder="capitalize($t(name))"
        />
        <small
            v-if="errors.length"
            class="text-danger"
        >{{ errors[0] }}
        </small>
      </validation-provider>

      <cleave
          v-else
          :id="name+ (listIndex != -1?(' '+(listIndex+1)):'')"
          v-model="localModel"
          class="form-control"
          :options="maskOptions"
          :placeholder="capitalize($t(name))"
      />
    </b-form-group>

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import Cleave                   from 'vue-cleave-component'
import { clone }                from '../../utils/utils'
import { capitalize }           from '../../utils/filter'
import { ValidationProvider }   from 'vee-validate'

export default {
  components: {
    Cleave,
    ValidationProvider
  },
  props: {
    model: {
      required: true
    },
    isAmount: {
      type: Boolean,
      default: false
    },
    isPercent: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    },
    displayLabel: {
      type: Boolean,
      default: true
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    listIndex: {
      type: Number,
      default: -1
    },
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const localModel = ref(props.model)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const maskOptions = computed(() => {
      if (props.isAmount == true) {
        return {
          numeral: true,
          numeralDecimalScale: 2,
          delimiter: ' ',
          // prefix: ' €',
          // tailPrefix: true
        }
      } else if (props.isPercent == true) {
        return {
          numeral: true,
          numeralDecimalScale: 0,
          numeralPositiveOnly: true,
          delimiter: ' ',
          numeralIntegerScale: 3,
          // prefix: ' %',
          // tailPrefix: true
        }
      } else {
        return {}
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(() => props.model, val => {
      if (localModel.value != val) {
        localModel.value = clone(val)
      }
    })

    watch(localModel, val => {
      if (props.isPercent == true && val > 100) {
        localModel.value = 100
      } else {
        emit('update:model', val)
        emit('input', val)
        emit('updated')
      }

    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      localModel,

      // Computed
      maskOptions,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { capitalize },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>