<template>
  <div>
    <b-modal
        v-if="'company' in workFloLocal"
        :title="title"
        v-model="isOpenLocal"
        size="lg"
        scrollable
        :hide-footer="hideFooter"
        :ok-title="capitalize($t('validate'))"
        :cancel-title="capitalize($t('cancel'))"
        @hide="tryCloseWorkFloModal"
        @hidden="handleCancel"
        @ok="handleOk"
    >
      <!--      Line 1-->
      <b-row>
        <b-col
            md="4"
            class="d-flex"
            v-b-tooltip.hover.bottom="workFloLocal.company._display"
        >
          <div class="m-auto">
            <b>{{ workFloLocal.company._display }}</b>
          </div>
        </b-col>
        <b-col md="4">
          <div
              class="d-flex"
              v-b-tooltip.hover.bottom="workFloLocal.title"
          >
            <div class="mx-auto w-100">

              <div class="d-flex justify-content-center">
                <div class="text-truncate">
                  {{ workFloLocal.title }}
                </div>

                <external-link
                    @click.native.stop="$router.push({name: 'WorkFlo view', params: { workFloId: workFloLocal.id }})"
                    @middleClick="openInNewTab"
                    :withIcon="true"
                    :withText="false"
                    :withBorder="false"
                    size="sm"
                />
              </div>
            </div>

          </div>

          <div class="text-center mt-50">
            <b-badge class="mw-100">
              <span v-if="workFloLocal.businessSector != null">{{ capitalize($t(workFloLocal.businessSector)) }}</span>
              <span v-else>-</span>
            </b-badge>
          </div>

        </b-col>
        <b-col md="4">
          <div class="d-flex">
            <div class="text-center text-truncate">
              <b-badge class="mw-100">
                {{ workFloLocal.status.name }}
              </b-badge>

              <div>
                <div
                    class="d-flex my-auto text-truncate"
                    v-b-tooltip.hover.bottom="workFloLocal.assignment != null ? workFloLocal.assignment._display : '?'"
                >
                  <b-avatar
                      v-if="workFloLocal.assignment != null && workFloLocal.assignment.avatar"
                      class="pull-up2"
                      :src="workFloLocal.assignment.avatar.url"
                      variant="light-primary"
                      size="sm"
                  />
                  <b-avatar
                      v-else
                      class="pull-up2"
                      :text="workFloLocal.assignment!=null?initials(workFloLocal.assignment._display):'?'"
                      variant="light-primary"
                      size="sm"
                  />

                  <div class="ml-50 text-truncate">
                    {{ workFloLocal.assignment != null ? workFloLocal.assignment._display : '?' }}
                  </div>
                </div>
              </div>
            </div>

            <div class="ml-50 my-auto">
              <view-button
                  @click.native.stop="$emit('view')"
                  :withIcon="true"
                  :withText="false"
                  :withBorder="false"
                  class="mr-50"
                  size="sm"
              />
              <button-edit
                  @click.native.stop="$emit('edit')"
                  :withIcon="true"
                  :withText="false"
                  :withBorder="false"
                  size="sm"
              />
            </div>
          </div>


        </b-col>

      </b-row>

      <!--      Last Event -->
      <b-row class="mt-2">
        <b-col md="3">
          <!--          <div class="d-flex cursor-pointer">-->
          <!--            <b-badge-->
          <!--                pill-->
          <!--                :variant="currentBadgeColor"-->
          <!--                class="mr-1"-->
          <!--            ></b-badge>-->
          <!--            <div>-->
          <!--              <b>{{ capitalize($t(reminderLocal.priority)) }}</b>-->
          <!--            </div>-->
          <!--          </div>-->
        </b-col>

        <b-col md="5">
          <div
              class="text-center text-truncate"
              v-b-tooltip.hover.bottom="displayLastActivity.text"
          >
            <b-badge
                :variant="displayLastActivity.color"
                style="padding: 0.4rem 0.5rem;"
                class="text-truncate"
            >
              <icon
                  :icon="displayLastActivity.icon.icon"
                  class="mr-50"
              />
              {{ displayLastActivity.text }}
            </b-badge>
          </div>

          <div class="text-center">
            <small>
              <i>{{ displayLastActivity.dateInfo }}</i>
            </small>
          </div>
        </b-col>

        <b-col md="4">
          <div class="d-flex">
            <div
                v-b-tooltip.hover.right="$t('Number of days since prospect date')"
                class="text-muted"
            >
              <small>{{ workFlo._daysSinceProspectDate }} {{ $tc('day', workFlo._daysSinceProspectDate) }}</small>
            </div>
            <div
                v-b-tooltip.hover.left="$t('Total time spent on the opportunity')"
                class="ml-50 text-muted"
            >
              <small>({{ workFlo._hoursSpent }}h)</small>
            </div>
            <add-button
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                size="sm"
                class="ml-50"
                @click.native.stop.prevent="$emit('addEvent')"
            />
          </div>
        </b-col>

      </b-row>

      <!--      Estimated turnover-->
      <b-row class="mt-2">
        <b-col md="12">
          <div
              v-b-hover="handleTurnoverHover"
          >
            <b
                v-if="!isTurnoverHovered"
            >{{ currency(workFloLocal.estimatedTurnover) }} HT
            </b>

            <!--        Estimated turnover-->
            <div v-else>
              <mask-input
                  :model.sync="workFloLocal.estimatedTurnover"
                  :is-amount="true"
                  :display-label="false"
                  :is-required="true"
                  name="estimatedTurnover"
                  placeholder="estimatedTurnover"
              />
            </div>
          </div>

          <div class="d-flex">
            <div class="d-flex text-truncate">
              <b-badge
                  v-for="workFloNeed in workFloLocal.workFloNeeds"
                  class="my-auto text-truncate mr-50"
              >
                {{ workFloNeed.article.name }}
              </b-badge>
            </div>

            <add-button
                @click.native.stop="$emit('edit', {defaultTab:'needs'})"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                class="ml-50"
                size="sm"
            />
          </div>

        </b-col>
      </b-row>

      <!--      Probability-->
      <b-row
          class="mt-2"
      >
        <b-col
            v-b-hover="handleProbabilityHover"
            md="4"
        >
          <b
              v-if="!isProbabilityHovered"
              v-b-tooltip.hover.bottom="capitalize($t('probability'))"
          >{{ percent(workFloLocal.probability) }}
          </b>

          <div v-if="isProbabilityHovered">
            <mask-input
                :model.sync="workFloLocal.probability"
                :is-percent="true"
                :display-label="false"
                :is-required="true"
                name="probability"
                placeholder="probability"
            />
          </div>

        </b-col>
        <b-col
            v-b-hover="handleClosingDateHover"
            md="8"
        >
          <div v-if="!isClosingDateHovered">
            {{ capitalize($t('closingDate')) }}
            <b>{{ estimatedClosingDate }}</b>
          </div>
          <div v-else>
            <input-date
                name="endOfProject"
                :display-label="false"
                placeholder="endOfProject"

                :model.sync="workFloLocal.estimatedClosingDate"
                :inline="true"
            />
          </div>
        </b-col>
      </b-row>

      <!--      Contact-->
      <b-row
          v-if="workFloLocal.mainContact != null"
          class="mt-2"
      >
        <b-col md="12">
          <contact-card
              :contact="workFloLocal.mainContact"
              class="mx-auto"
              :is-calendar-disabled="true"
              :is-task-disabled="true"

              @enveloppeClick="customLink('mail')"
              @phoneClick="customLink('tel')"
          />
          <!--          <contact-card-->
          <!--              v-for="(contact, index) in workFloLocal.otherContacts"-->
          <!--              :key="'otherContact-'+index"-->
          <!--              :contact="contact"-->
          <!--              class="ml-2"-->
          <!--          />-->
        </b-col>
      </b-row>

    </b-modal>
  </div>
</template>

<script>
import { ref, computed, watch }          from '@vue/composition-api'
import FormWorkFlo                       from '../../../../components/form/workFlo/WorkFlo.vue'
import useAPI                            from '../../../../utils/useAPI'
import { capitalize, currency, percent } from '../../../../utils/filter'
import { ValidationObserver }            from 'vee-validate'
import ExternalLink                      from '../../../../components/button/ExternalLink.vue'
import ButtonEdit                        from '../../../../components/button/Edit.vue'
import ViewButton                        from '../../../../components/button/View.vue'
import { clone, initials }               from '../../../../utils/utils'
import i18n                              from '../../../../libs/i18n'
import moment                            from 'moment'
import AddButton                         from '../../../../components/button/Add.vue'
import router                            from '../../../../router'
import ContactCard                       from '../../../../components/card/ContactCard.vue'
import MaskInput                         from '../../../../components/input/MaskInput.vue'
import InputDate                         from '../../../../components/input/Date.vue'

export default {
  components: {
    InputDate,
    MaskInput,
    ContactCard,
    AddButton,
    ButtonEdit,
    ExternalLink,
    FormWorkFlo,
    ViewButton,
    ValidationObserver
  },
  props: {
    workFlo: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: ''
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isOpenLocal = ref(false)
    const workFloLocal = ref(props.workFlo)
    const submitWorkFloFormRef = ref(null)
    const observerRef = ref(null)
    const isProbabilityHovered = ref(false)
    const isClosingDateHovered = ref(false)
    const isTurnoverHovered = ref(false)
    const probabilityInit = ref()
    const closingDateInit = ref()
    const turnoverInit = ref()

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { currentBreakPoint } = useAPI()

    const hideFooter = computed(() => {
      return !['xs', 'sm'].includes(currentBreakPoint.value)
    })

    const displayLastActivity = computed(() => {
      if (
          '_lastActivity' in props.workFlo &&
          props.workFlo._lastActivity.type != null
      ) {
        let color = 'secondary'
        let now = moment()

        // if (props.workFlo._lastActivity.entity.type != null) {
        let type = capitalize(i18n.tc('event'))

        if (
            props.workFlo._lastActivity.entity.type == 'call' ||
            props.workFlo._lastActivity.entity.type == 'discussion' ||
            props.workFlo._lastActivity.entity.type == 'mail'
        ) {
          type = capitalize(i18n.t('exchange'))
        } else if (props.workFlo._lastActivity.entity.type == 'intervention') {
          type = capitalize(i18n.tc('intervention'))
        } else if (props.workFlo._lastActivity.entity.type == 'trip') {
          type = capitalize(i18n.tc('trip'))
        }

        if (props.workFlo._lastActivity.type == 'event') {
          let date = moment(props.workFlo._lastActivity.entity.startDate)
          let text = i18n.t('Xscheduled', { type: type }) + ' ' + displayDate(props.workFlo._lastActivity.entity.startDate)
          let icon = getIcon(props.workFlo._lastActivity.entity.type)
          let dateInfo = date.format('LL')

          if (
              now.format('YYYY-MM-DD') != date.format('YYYY-MM-DD') &&
              date < now
          ) {
            color = 'danger'
            icon = {
              icon: 'exclamation-triangle',
              library: ''
            }
            text = capitalize(i18n.t('noActionPlanned'))
            dateInfo = capitalize(i18n.t('since', { since: date.fromNow(true) }))
          }

          return {
            icon: icon,
            color: color,
            text: text,
            dateInfo: dateInfo
          }
        } else if (props.workFlo._lastActivity.type == 'reminder') {
          let date = moment(props.workFlo._lastActivity.entity.completionDate)
          let text = i18n.t('Xscheduled', { type: type }) + ' ' + displayDate(props.workFlo._lastActivity.entity.completionDate)
          let icon = getIcon(props.workFlo._lastActivity.entity.type)

          if (
              now.format('YYYY-MM-DD') != date.format('YYYY-MM-DD') &&
              date < now
          ) {
            color = 'danger'
            icon = 'exclamation-triangle'
            text = capitalize(i18n.t('noActionPlanned'))
          }

          return {
            icon: icon,
            color: color,
            text: text,
            dateInfo: moment(props.workFlo._lastActivity.entity.completionDate).format('LL'),
          }
        } else if (props.workFlo._lastActivity.type == 'email') {
          let date = moment(props.workFlo._lastActivity.entity.receivedDateTime)
          let icon = getIcon(props.workFlo._lastActivity.type)
          let text = capitalize(i18n.t('emailSent')) + ' ' + displayDate(props.workFlo._lastActivity.entity.receivedDateTime)

          if (
              now.format('YYYY-MM-DD') != date.format('YYYY-MM-DD') &&
              date < now
          ) {
            color = 'danger'
          }

          return {
            icon: icon,
            color: color,
            text: text,
            dateInfo: date.format('LL'),
          }
        } else if (props.workFlo._lastActivity.type == 'offer') {
          let date = moment(props.workFlo._lastActivity.entity.billNumber==null?props.workFlo._lastActivity.entity._createdAt:props.workFlo._lastActivity.entity.billingDate)
          let icon = getIcon(props.workFlo._lastActivity.type)
          let text = capitalize(i18n.tc('offer')) + ' ' + displayDate(date)

          if (
              now.format('YYYY-MM-DD') != date.format('YYYY-MM-DD') &&
              date < now
          ) {
            color = 'danger'
          }

          return {
            icon: icon,
            color: color,
            text: text,
            dateInfo: date.format('LL'),
          }
        } else if (props.workFlo._lastActivity.type == 'customerInvoice') {
          let date = moment(props.workFlo._lastActivity.entity.billingDate)
          let icon = getIcon(props.workFlo._lastActivity.type)
          let text = capitalize(i18n.tc('customerInvoice')) + ' ' + displayDate(date)

          if (
              now.format('YYYY-MM-DD') != date.format('YYYY-MM-DD') &&
              date < now
          ) {
            color = 'danger'
          }

          return {
            icon: icon,
            color: color,
            text: text,
            dateInfo: date.format('LL'),
          }
        }
      } else {
        return {
          icon: {
            icon: 'exclamation-triangle',
            library: ''
          },
          color: 'danger',
          text: capitalize(i18n.t('noActionPlanned'))
        }
      }
    })

    const estimatedClosingDate = computed(() => {
      if (workFloLocal.value.estimatedClosingDate == null) {
        return '-'
      } else {
        return moment(workFloLocal.value.estimatedClosingDate).format('LL')
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(isOpenLocal, (val) => {
      emit('update:isOpen', val)
    })
    watch(workFloLocal, (val) => {
      emit('update:workFlo', val)
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const tryCloseWorkFloModal = (modalEvent) => {
      // if (modalEvent.trigger == 'backdrop') {
      //   // Click outside
      //   modalEvent.preventDefault()
      //   submitWorkFloFormRef.value.click()
      // }
    }

    const submitValidatedWorkFloLocal = () => {
      emit('submitValidated')
    }

    const handleOk = async (bvModalEvent) => {
      bvModalEvent.preventDefault()

      const isValid = await observerRef.value.validate()
      if (isValid) {
        submitValidatedWorkFloLocal()
      }
    }

    const handleCancel = () => {}

    const getIcon = type => {
      if (type == 'work') {
        return {
          icon: 'user-circle',
          library: 'fas'
        }
      } else if (type == 'discussion') {
        return {
          icon: 'comments',
          library: 'fas'
        }
      } else if (type == 'call') {
        return {
          icon: 'phone',
          library: 'fas'
        }
      } else if (type == 'mail') {
        return {
          icon: 'envelope',
          library: 'fas'
        }
      } else if (type == 'intervention') {
        return {
          icon: 'briefcase',
          library: 'fas'
        }
      } else if (type == 'trip') {
        return {
          icon: 'suitcase-rolling',
          library: 'fas'
        }
      } else if (type == 'email') {
        return {
          icon: 'envelope',
          library: 'fas'
        }
      } else if (type == 'offer' || type == 'customerInvoice') {
        return {
          icon: 'file-invoice',
          library: 'fas'
        }
      } else {
        return {
          icon: 'exclamation-triangle',
          library: 'fas'
        }
      }
    }

    const displayDate = (date) => {
      let now = moment()
      let tomorrow = moment(clone(now)).add(1, 'days')

      if (now.format('YYYY-MM-DD') == moment(date).format('YYYY-MM-DD')) {
        return i18n.t('today')
      } else if (tomorrow.format('YYYY-MM-DD') == moment(date).format('YYYY-MM-DD')) {
        return i18n.t('tomorrow')
      } else {
        return i18n.t('the') + '  ' + (moment(date).format('LL'))
      }
    }

    const openInNewTab = () => {
      window.open(router.resolve({ name: 'WorkFlo view', params: { workFloId: workFloLocal.value.id } }).href, '_blank')
    }

    const customLink = type => {
      if (type == 'mail') {
        window.location = 'mailto:' + workFloLocal.value.mainContact.email
      } else if (type == 'tel') {
        window.location = 'tel:' + workFloLocal.value.mainContact.phone
      }
    }

    const handleProbabilityHover = hovered => {
      if (hovered == true) {
        probabilityInit.value = clone(parseFloat(workFloLocal.value.probability).toFixed(2))
      } else {
        if (parseFloat(workFloLocal.value.probability).toFixed(2) != probabilityInit.value) {
          submitValidatedWorkFloLocal()
        }
      }
      isProbabilityHovered.value = hovered
    }

    const handleClosingDateHover = hovered => {
      if (hovered == true) {
        if (isClosingDateHovered.value !== true) {
          closingDateInit.value = clone(moment(workFloLocal.value.estimatedClosingDate).format('LL'))
        }
      } else {
        if (moment(workFloLocal.value.estimatedClosingDate).format('LL') != closingDateInit.value) {
          submitValidatedWorkFloLocal()
        }
      }
      isClosingDateHovered.value = hovered
    }

    const handleTurnoverHover = hovered => {
      if (hovered == true) {
        turnoverInit.value = clone(parseFloat(workFloLocal.value.estimatedTurnover).toFixed(2))
      } else {
        if (parseFloat(workFloLocal.value.estimatedTurnover).toFixed(2) != turnoverInit.value) {
          submitValidatedWorkFloLocal()
        }
      }
      isTurnoverHovered.value = hovered
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data
      isOpenLocal,
      workFloLocal,
      submitWorkFloFormRef,
      observerRef,
      isProbabilityHovered,
      isClosingDateHovered,
      isTurnoverHovered,

      // Computed
      hideFooter,
      displayLastActivity,
      estimatedClosingDate,

      // Methods
      tryCloseWorkFloModal,
      submitValidatedWorkFloLocal,
      handleOk,
      handleCancel,
      openInNewTab,
      customLink,
      handleProbabilityHover,
      handleClosingDateHover,
      handleTurnoverHover,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {
    workFlo: function (val) {
      this.workFloLocal = val
    },
    isOpen: function (val) {
      this.isOpenLocal = val
    }
  },
  methods: { currency, percent, initials },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>