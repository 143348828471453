<template>
  <div>
    <work-flos-view3/>
  </div>
</template>

<script>
import { ref, computed, watch, onUnmounted } from '@vue/composition-api'

import WorkFlosView3   from '../../components/views/WorkFlosView3.vue'
import { useEmails }   from '../../components/email/v2/useEmails'
import store           from '../../store'
import { getUserData } from '../../utils/utils'
import ability         from '../../libs/acl/ability'

export default {
  components: { WorkFlosView3 },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { refreshEmails } = useEmails()

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------
    let interval
    // if (getUserData().roles.includes('ROLE_ADMIN')) {
    if (ability.can('manage', 'Emails')) {
      onUnmounted(() => {
        clearInterval(interval)
      })
    }

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    // if (getUserData().roles.includes('ROLE_ADMIN')) {
    if (ability.can('manage', 'Emails')) {
      refreshEmails()
      interval = setInterval(() => {
        if (store.getters['app/emailLoaders'] == 0) {
          refreshEmails()
        }
      }, 2 * 60000)
    }

    return {
      // Components

      // Data

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>