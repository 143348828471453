<template>
  <div>

    <b-tabs v-model="tabIndex">
      <b-tab
          :title="capitalize($t('general'))"
          :title-link-class="{'text-danger font-weight-bolder': isWorkFloGeneralError}"
      >

        <work-flo-general
            :work-flo.sync="workFloLocal"

            @isProspectingChange="isProspectingChange"
        />

      </b-tab>

      <b-tab
          :title="capitalize($tc('need',2))"
          :title-link-class="{'text-danger font-weight-bolder': isWorkFloNeedsError}"
      >
        <work-flo-needs
            :work-flo-needs.sync="workFloLocal.workFloNeeds"
        />
      </b-tab>

      <b-tab
          :title="capitalize($t('status'))"
          :title-link-class="{'text-danger font-weight-bolder': isWorkFloStatusError}"
      >
        <work-flo-status-date
            :work-flo="workFloLocal"
            ref="workFloStatusDateRef"
        />
      </b-tab>
    </b-tabs>


  </div>
</template>

<script>
import { ref, computed, watch }  from '@vue/composition-api'
import { capitalize }            from '../../../utils/filter'
import { getUserData, isObject } from '../../../utils/utils'

import WorkFloGeneral    from './components/_workFloGeneral.vue'
import WorkFloNeeds      from './components/_workFloNeeds.vue'
import WorkFloStatusDate from './components/_workFloStatusDate.vue'

import InputField     from '../../input/Input.vue'
import InputSelect2   from '../../input/Select2.vue'
import InputContact   from '../../input/Contact2.vue'
import InputTextarea  from '../../input/Textarea.vue'
import InputSwitch    from '../../input/Switch.vue'
import InputDate      from '../../input/Date.vue'
import InputEmployees from '../../input/Employee.vue'

import useAPI from '../../../utils/useAPI'
import i18n   from '../../../libs/i18n'
import store  from '../../../store'
import moment from 'moment'

export default {
  components: {
    WorkFloGeneral,
    WorkFloNeeds,
    InputDate,
    InputEmployees,
    WorkFloStatusDate
  },
  props: {
    workFlo: {
      type: Object,
      default: () => {}
    },
    formErrors: {
      type: Object,
      default: () => {}
    },
    workFloModalDefaultTab: {
      type: String,
      default: 'general'
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const workFloStatusDateLocal = ref([])
    const isWorkFloGeneralError = ref(false)
    const isWorkFloNeedsError = ref(false)
    const isWorkFloStatusError = ref(false)
    const workFloStatusDateRef = ref()
    const componentKey = ref(0)
    const tabIndex = ref(0)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const {
      autocompleteWorkFloStatus,
      autocompleteEmployees
    } = useAPI()

    const workFloLocal = computed({
      get () {
        return props.workFlo
      },
      set (val) {
        emit('update:workFlo', val)
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(() => props.formErrors, val => {
      isWorkFloGeneralError.value = false
      isWorkFloNeedsError.value = false
      isWorkFloStatusError.value = false

      for (const [field, errorArrray] of Object.entries(val)) {
        if (
            errorArrray.length > 0 &&
            (
                field.startsWith('article') || field.startsWith('article') ||
                field.startsWith('quantité') || field.startsWith('quantity')
            )
        ) {
          isWorkFloNeedsError.value = true
        }

        if (
            (
                (field == 'titre' || field == 'title') ||
                (field == 'affectation' || field == 'assignment') ||
                (field == 'statut' || field == 'status') ||
                (field == 'société' || field == 'company')
            ) &&
            errorArrray.length > 0
        ) {
          isWorkFloGeneralError.value = true
        }

      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const isProspectingChange = () => {
      workFloStatusDateRef.value.isProspectingChange()

    }

    const populateIndex = title => {
      if (title == 'needs') {
        tabIndex.value = 1
      } else if (title == 'status') {
        tabIndex.value = 2
      } else {
        tabIndex.value = 0
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    // populateWorkFloStatusDate()
    populateIndex(props.workFloModalDefaultTab)

    return {
      // Components
      capitalize,

      // Data
      workFloStatusDateLocal,
      isWorkFloGeneralError,
      isWorkFloNeedsError,
      isWorkFloStatusError,
      workFloStatusDateRef,
      componentKey,
      tabIndex,

      // Computed
      workFloLocal,

      // Methods
      // updateWorkFloStatusDate,
      // updateWorkFloStatusDateOwner,
      isProspectingChange,
    }
  },
  data
      () {
    return {}
  }
  ,
  computed: {}
  ,
  watch: {}
  ,
  methods: {}
  ,
  mounted () {
  }
  ,
  created () {
  }
}
</script>

<style lang="scss">
.inputSwitch {
  height: 57.5px;
}
</style>