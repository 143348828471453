<template>
  <b-col cols="12">

    <b-row>
      <b-col md="8">
        <field-article
            :article.sync="workFloNeedsLocal.article"
            :listIndex="index"

        />
      </b-col>
      <b-col md="4">
        <div class="d-flex justify-content-between">
          <input-field
              name="quantity"
              :listIndex="index"
              rules="decimal|required"
              :model.sync="workFloNeedsLocal.quantity"
              class="w-100"
          />

          <remove-button
              size="sm"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              @click.native.stop="$emit('removeWorkFloNeed')"
              class="ml-1"
          />
        </div>
      </b-col>
    </b-row>

  </b-col>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import FieldArticle from '../../../input/Article.vue'
import InputField from '../../../input/Input.vue'
import RemoveButton from '../../../button/Remove.vue'

export default {
  components: { FieldArticle, InputField, RemoveButton },
  props: {
    workFloNeeds: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      required: true
    },
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const workFloNeedsLocal = computed({
      get () {
        return props.workFloNeeds
      },
      set (val) {
        emit('update:workFloNeeds', val)
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed
      workFloNeedsLocal,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>