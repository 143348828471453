<template>
  <div>

    <transition-group
        name="flip-list"
        tag="div"
    >
      <b-row
          v-for="(workFloNeed,index) in workFloNeedsLocal"
          :key="'workFloNeed_'+index"
          class="list-item"
      >
        <work-flo-needs-articles
            :work-flo-needs.sync="workFloNeed"
            :index="index"

            @removeWorkFloNeed="removeWorkFloNeed(index)"
        />
      </b-row>
    </transition-group>

    <button-add
        size="sm"
        @click="addNewWorkFloNeed"
        :text="$t('AddNeed')"
    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import ButtonAdd from '../../../button/Add.vue'
import WorkFloNeedsArticles from './_workFloNeedsArticles.vue'

export default {
  components: {
    WorkFloNeedsArticles,
    ButtonAdd,
  },
  props: {
    workFloNeeds: {
      type: Array,
      default: () => []
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const workFloNeedsLocal = computed({
      get () {
        return props.workFloNeeds
      },
      set (val) {
        emit('update:workFloNeeds', val)
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const addNewWorkFloNeed = () => {
      workFloNeedsLocal.value.push({
        article: null,
        quantity: 1
      })
    }

    const removeWorkFloNeed = (index) => {
      workFloNeedsLocal.value.splice(index, 1)
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed
      workFloNeedsLocal,

      // Methods
      addNewWorkFloNeed,
      removeWorkFloNeed,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>
.flip-list-move {
  transition: transform 1s;
}
</style>