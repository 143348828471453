<template>
  <div>

    <h1>{{ capitalize($t('Commercial')) }}</h1>

    <b-row>
      <b-col md="12">
        <div class="w-100 text-center mb-2">
          <small class="mr-50">{{ capitalize($t('incomingRequest')) }}</small>
          <b-form-checkbox
              v-model="workFloLocal.isProspecting"
              name="isProspecting"
              switch
              inline

              @input="$emit('isProspectingChange')"
          >
            {{ capitalize($t('prospecting')) }}
          </b-form-checkbox>
        </div>
      </b-col>
    </b-row>

    <!--    Incoming request-->
    <b-row>
      <b-col md="6">
        <!--    typeOfSale-->
        <input-select2
            :model.sync="workFloLocal.typeOfSale"
            :options="typeOfSaleOptions"
            :is-required="true"
            :clearable="false"
            name="typeOfSale"
            :display-label="false"
            :placeholder="capitalize($t('salesCategory'))"

            @updated="workFloLocal.state = null; updateTypeOfPurchaseOptions()"
        />


        <!--    typeOfPurchase-->
        <input-select2
            :model.sync="workFloLocal.typeOfPurchase"
            :options="typeOfPurchaseOptions"
            name="typeOfPurchase"
            :display-label="false"
        />


        <!--    Usage-->
        <transition name="fade">
          <input-select2
              v-if="workFloLocal.typeOfSale != 'Vente indirecte' && workFloLocal.typeOfSale != 'Autre'"
              :model.sync="workFloLocal.workFloUsages"
              :multiple="true"
              :options="autocompleteWorkFloUsages"
              :display-label="false"
              label="name"
              name="usage"
          />
        </transition>

        <!--    typeOfApplication (catégorie de demande) -->
        <transition name="fade">
          <input-select2
              v-if="workFloLocal.typeOfSale != 'Vente indirecte' && workFloLocal.typeOfSale != 'Autre'"
              :model.sync="workFloLocal.typeOfApplication"
              :options="typeOfApplicationOptions"
              :display-label="false"
              name="typeOfApplication"
              :placeholder="capitalize($t('applicationCategory'))"
          />
        </transition>


        <!--        &lt;!&ndash;    source (canal de prospection) &ndash;&gt;-->
        <!--        <transition name="fade">-->
        <!--          <input-select2-->
        <!--              v-if="workFloLocal.isProspecting && workFloLocal.typeOfPurchase != 'Achat récurrent'"-->
        <!--              :model.sync="workFloLocal.source"-->
        <!--              :options="sourceOptions"-->
        <!--              :display-label="false"-->
        <!--              reduce="value"-->
        <!--              name="source"-->
        <!--              :placeholder="capitalize($t('prospecting channel'))"-->
        <!--          />-->
        <!--        </transition>-->

      </b-col>

      <b-col md="6">

        <!--    state-->
        <input-select2
            :model.sync="workFloLocal.state"
            :options="stateOptions"
            :is-required="true"
            :clearable="false"
            name="state"
            reduce="value"
            :append-to-body="false"
            :display-label="false"
        />

        <!--    state-->
        <input-select2
            v-if="workFloLocal.state == 'lost'"
            :model.sync="workFloLocal.lossCategory"
            :options="lossCategoryOptions"
            :is-required="true"
            :clearable="false"
            name="lossCategory"
            :append-to-body="false"
            :display-label="false"
        />

        <!--    stateReason-->
        <input-textarea
            v-if="isClosed"
            :model.sync="workFloLocal.stateReason"
            name="stateReason"
            :display-label="false"
            :is-required="true"
            :placeholder="capitalize($t('reasons for closing'))"
        />


      </b-col>

    </b-row>

    <b-row>
      <b-col md="6">

        <!--        Estimated turnover-->
        <mask-input
            :model.sync="workFloLocal.estimatedTurnover"
            :is-amount="true"
            :display-label="false"
            :is-required="true"
            name="estimatedTurnover"
            placeholder="estimatedTurnover"
        />


        <input-date
            name="endOfProject"
            :display-label="false"
            placeholder="endOfProject"

            :model.sync="workFloLocal.estimatedClosingDate"
        />

      </b-col>

      <b-col md="6">
        <!--        Probability-->
        <mask-input
            :model.sync="workFloLocal.probability"
            :is-percent="true"
            :display-label="false"
            :is-required="true"
            name="probability"
            placeholder="probability"
        />

      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize }           from '../../../../../utils/filter'

import InputSelect2  from '../../../../input/Select2.vue'
import useAPI        from '../../../../../utils/useAPI'
import i18n          from '../../../../../libs/i18n'
import InputTextarea from '../../../../input/Textarea.vue'
import { isObject }  from '../../../../../utils/utils'
import Cleave        from 'vue-cleave-component'
import InputDate     from '../../../../input/Date.vue'
import MaskInput     from '../../../../input/MaskInput.vue'

export default {
  components: { MaskInput, InputDate, InputTextarea, InputSelect2, Cleave },
  props: {
    workFlo: {
      type: Object,
      default: () => {}
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const typeOfSaleOptions = ref([
      'Vente directe',
      'Vente indirecte',
      'Autre'
    ])

    const typeOfPurchaseOptions = ref([
      'Premier achat',
      'Achat récurrent',
      'Achat post location',
    ])

    const typeOfApplicationOptions = ref([
      'Normal',
      'Événement',
      'Innovation',
      'Appel d\'offres',
    ])

    const lossCategoryOptions = ref([
      'Pas le budget/Trop cher',
      'Conccurence',
      'Produit non adapté à l’activité',
      'Démo. demandée sans qualification',
      'Projet abandonné/Plus prioritaire',
      'Autre solution trouvée',
      'Pas de réponse',
    ])

    const sourceOptions = ref([
      {
        value: 'reference',
        _display: capitalize(i18n.t('reference (customer, prescriber and network)')),
      },
      {
        value: 'cold call',
        _display: capitalize(i18n.t('cold call')),
      },
      {
        value: 'cold emailing',
        _display: capitalize(i18n.t('cold emailing')),
      },
      {
        value: 'network',
        _display: capitalize(i18n.tc('network')),
      },
      {
        value: 'farming',
        _display: capitalize(i18n.tc('farming')),
      },
    ].sort((a, b) => a._display < b._display ? -1 : 1))

    const stateOptions = ref([
      {
        _display: capitalize(i18n.t('inProgress')),
        value: 'in progress'
      },
      {
        _display: capitalize(i18n.t('success')),
        value: 'success'
      },
      {
        _display: capitalize(i18n.t('lost')),
        value: 'lost'
      }
    ].sort((a, b) => a._display < b._display ? -1 : 1))

    const isClosed = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { autocompleteWorkFloUsages } = useAPI()

    const workFloLocal = computed({
      get () {
        return props.workFlo
      },
      set (val) {
        emit('update:workFlo', val)
      }
    })

    // const stateOptions = computed(() => {
    //   let output = []
    //
    //   if (workFloLocal.value.isProspecting === false) {
    //     output = [
    //       {
    //         display: capitalize(i18n.t('inProgress')),
    //         value: 'in progress'
    //       },
    //       {
    //         display: capitalize(i18n.t('success')),
    //         value: 'success'
    //       },
    //       {
    //         display: capitalize(i18n.t('lost')),
    //         value: 'lost'
    //       }
    //     ]
    //   } else {
    //
    //   }
    //
    //   return output
    // })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(() => props.workFlo, val => {
      if (
          val.state == 'lost' ||
          (
              val.status && isObject(val.status) && val.status.name == 'Cloturé'
          )
      ) {
        isClosed.value = true
      }
    }, { deep: true })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const updateTypeOfPurchaseOptions = () => {
      // console.log(JSON.parse(JSON.stringify(typeOfSaleOptions.value)))
      if (props.workFlo.typeOfSale == 'Vente indirecte') {
        typeOfPurchaseOptions.value = typeOfPurchaseOptions.value.filter(x => x != 'Achat post location')
        typeOfPurchaseOptions.value.push('Volume')
      } else {
        typeOfPurchaseOptions.value = typeOfPurchaseOptions.value.filter(x => x != 'Volume' && x != 'Achat post location')
        typeOfPurchaseOptions.value.push('Achat post location')
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    updateTypeOfPurchaseOptions()

    return {
      // Components
      capitalize,

      // Data
      typeOfSaleOptions,
      typeOfPurchaseOptions,
      typeOfApplicationOptions,
      lossCategoryOptions,
      sourceOptions,
      isClosed,

      // Computed
      workFloLocal,
      autocompleteWorkFloUsages,
      stateOptions,

      // Methods
      updateTypeOfPurchaseOptions,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>