<template>
  <div>
    <div
        v-for="(statusDate, index) in workFloStatusDateLocal"
        :key="'workFloStatusDate_'+index"
        class="mb-2"
    >
      <b-row>
        <b-col md="12">
          <h3>{{ statusDate.workFloStatus.name }}</h3>
        </b-col>

        <b-col md="4">
          <input-date
              :name="statusDate._dateLabel"
              :model.sync="statusDate.date"
              :disabled="isDisabled(index, statusDate)"

              @input="updateWorkFloStatusDate(statusDate)"
          />
        </b-col>

        <b-col md="8">
          <input-employees
              name="owner"
              :model.sync="statusDate.owner"
              :key="componentKey"
              :disabled="isDisabled(index, statusDate)"
              :clearable="index==7"

              @input="updateWorkFloStatusDateOwner(statusDate)"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch }  from '@vue/composition-api'
import { getUserData, isObject } from '../../../../utils/utils'

import InputDate      from '../../../input/Date.vue'
import InputEmployees from '../../../input/Employee.vue'

import useAPI from '../../../../utils/useAPI'
import moment from 'moment'

export default {
  components: { InputEmployees, InputDate },
  props: {
    workFlo: {
      type: Object,
      default: () => {}
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const workFloStatusDateLocal = ref([])
    const componentKey = ref(0)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const {
      autocompleteWorkFloStatus,
      autocompleteEmployees
    } = useAPI()

    const workFloLocal = computed({
      get () {
        return props.workFlo
      },
      set (val) {
        emit('update:workFlo', val)
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(workFloStatusDateLocal, val => {
      // console.log(JSON.parse(JSON.stringify(val)))
      // console.log(JSON.parse(JSON.stringify(workFloLocal.value)))
      let last = val[0]
      val.forEach(wfsd => {
        if (
            wfsd.workFloStatus.id > last.workFloStatus.id &&
            wfsd.date == null &&
            wfsd.owner != null
        ) {
          workFloLocal.value.status = autocompleteWorkFloStatus.value.find(x => x.id == wfsd.workFloStatus.id)
          workFloLocal.value.assignment = isObject(wfsd.owner) ? wfsd.owner : autocompleteEmployees.value.find(e => e.id == wfsd.owner)

          last = wfsd
        }

        if (
            wfsd.workFloStatus.id == 8 &&
            wfsd.date != null &&
            wfsd.owner != null
        ) {
          last = wfsd
          workFloLocal.value.status = autocompleteWorkFloStatus.value.find(x => x.id == 8)
          workFloLocal.value.assignment = isObject(wfsd.owner) ? wfsd.owner : autocompleteEmployees.value.find(e => e.id == wfsd.owner)
        }
      })

    }, { deep: true })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const populateWorkFloStatusDate = () => {
      workFloStatusDateLocal.value = []

      autocompleteWorkFloStatus.value.forEach(s => {
        let existant = workFloLocal.value.workFloStatusDates.find(wsd => wsd.workFloStatus.id == s.id)

        workFloStatusDateLocal.value.push({
          workFloStatus: s,
          date: existant === undefined ? null : existant.date,
          owner: existant === undefined ? null : existant.owner,
          _dateLabel: getDateLabel(s.name)
        })
      })

      // console.log(JSON.parse(JSON.stringify(workFloStatusDateLocal.value)))
    }

    const getDateLabel = (statusName) => {
      if (statusName == 'Prospect') {
        return 'Date de l’opportunité démarrée'
      } else if (statusName == 'Lead') {
        return 'Date de contact du lead'
      } else if (statusName == 'En qualification') {
        return 'Date du lead qualifié'
      } else if (statusName == 'SQL/Lead Qualifié') {
        return 'Date d\'offre envoyée'
      } else if (statusName == 'Offre réalisée, en attente de la commande') {
        return 'Date de la commande'
      } else if (statusName == 'Commande reçue, en cours de livraison') {
        return 'Date de livraison effectuée'
      } else if (statusName == 'Suivi') {
        return 'Date de suivi'
      } else if (statusName == 'Cloturé') {
        return 'Date de fin de l’opportunité'
      }
    }

    const updateWorkFloStatusDate = (statusDate) => {
      // console.log(statusDate)

      let index = workFloLocal.value.workFloStatusDates.findIndex(wsd => wsd.workFloStatus.id == statusDate.workFloStatus.id)

      // console.log(index)
      // console.log(workFloLocal.value.workFloStatusDates[index])

      if (index == -1 && statusDate != null) {
        // New date
        let wsd = {
          workFloStatus: statusDate.workFloStatus,
          date: statusDate.date,
          owner: getUserData().id,
          _dateLabel: getDateLabel(statusDate.workFloStatus.name)
        }
        workFloLocal.value.workFloStatusDates.push(wsd)

        if (statusDate.workFloStatus.id != 8) {
          let wsdNext = {
            workFloStatus: getNextStatus(statusDate.workFloStatus),
            date: null,
            owner: getUserData().id,
            _dateLabel: getDateLabel(getNextStatus(statusDate.workFloStatus).name)
          }

          let nextIndex = workFloLocal.value.workFloStatusDates.findIndex(wsd => wsd.workFloStatus.id == wsdNext.workFloStatus.id)
          if (nextIndex == -1) {
            workFloLocal.value.workFloStatusDates.push(wsdNext)
          }
        }

        let indexLocal = workFloStatusDateLocal.value.findIndex(wsdl => wsdl.workFloStatus.id == statusDate.workFloStatus.id)
        workFloStatusDateLocal.value[indexLocal].owner = getUserData().id
        if (statusDate.workFloStatus.id != 8) {
          workFloStatusDateLocal.value[indexLocal + 1].owner = getUserData().id
        }

      } else {
        // console.log(index)
        console.log("A")
        // console.log(JSON.parse(JSON.stringify(workFloLocal.value.workFloStatusDates[index])))

        if (
            statusDate.workFloStatus.id == 8 &&
            statusDate.date == null &&
            statusDate.owner == null
        ) {
          console.log("B")
          workFloLocal.value.workFloStatusDates.splice(index, 1)
          // console.log(workFloLocal.value.workFloStatusDates)
        } else {
          workFloLocal.value.workFloStatusDates[index].date = statusDate.date
          workFloLocal.value.workFloStatusDates[index].owner = statusDate.owner

          if (statusDate.date == null) {
            workFloLocal.value.workFloStatusDates = workFloLocal.value.workFloStatusDates.filter(wfsdl => {
              return wfsdl.workFloStatus.id <= statusDate.workFloStatus.id
            })

            workFloStatusDateLocal.value.forEach((wfsdl, i) => {
              if (wfsdl.workFloStatus.id > statusDate.workFloStatus.id) {
                workFloStatusDateLocal.value[i].date = null
                workFloStatusDateLocal.value[i].owner = null
              }
            })
          }

          let nextExist = workFloLocal.value.workFloStatusDates.some(wfsdl => wfsdl.workFloStatus.id == (statusDate.workFloStatus.id + 1))
          // console.log(nextExist)

          if (statusDate.date != null && !nextExist && statusDate.workFloStatus.id != 8) {
            let wsdNext = {
              workFloStatus: getNextStatus(statusDate.workFloStatus),
              date: null,
              owner: getUserData().id,
              _dateLabel: getDateLabel(getNextStatus(statusDate.workFloStatus).name)
            }
            workFloLocal.value.workFloStatusDates.push(wsdNext)
            let indexLocal = workFloStatusDateLocal.value.findIndex(wsdl => wsdl.workFloStatus.id == statusDate.workFloStatus.id)
            workFloStatusDateLocal.value[indexLocal + 1].owner = getUserData().id
          }
        }
      }

      componentKey.value++

      // console.log(workFloStatusDateLocal.value)
    }

    const updateWorkFloStatusDateOwner = (statusDate) => {
      // console.log(JSON.parse(JSON.stringify(workFloLocal.value)))
      let index = workFloLocal.value.workFloStatusDates.findIndex(wsd => wsd.workFloStatus.id == statusDate.workFloStatus.id)

      if (index != -1) {
        workFloLocal.value.workFloStatusDates[index].owner = statusDate.owner
      }

      let indexLocal = workFloStatusDateLocal.value.findIndex(wsdl => wsdl.workFloStatus.id == statusDate.workFloStatus.id)
      if (indexLocal == 7) {
        workFloStatusDateLocal.value[7].date = null
        // console.log(JSON.parse(JSON.stringify(workFloStatusDateLocal.value)))
      }
    }

    const getNextStatus = currentStatus => {
      // let name =
      switch (currentStatus.name) {
        case 'Prospect':
          return {
            id: 2,
            name: 'Lead'
          }
        case 'Lead':
          return {
            id: 3,
            name: 'En qualification'
          }
        case 'En qualification':
          return {
            id: 4,
            name: 'SQL/Lead Qualifié'
          }
        case 'SQL/Lead Qualifié':
          return {
            id: 5,
            name: 'Offre réalisée, en attente de la commande'
          }
        case 'Offre réalisée, en attente de la commande':
          return {
            id: 6,
            name: 'Commande reçue, en cours de livraison'
          }
        case 'Commande reçue, en cours de livraison':
          return {
            id: 7,
            name: 'Suivi'
          }
        case 'Suivi':
          return {
            id: 8,
            name: 'Cloturé'
          }
      }
    }

    const isDisabled = (index, wfsd) => {
      // console.log(index, wfsd)
      if (index == 0 || index == 7) {
        return false
      } else {
        let existAfter = workFloStatusDateLocal.value.filter((wfsdl, i) => {
          return wfsdl.owner != null && i > index && i != 7
        })

        if (
            (wfsd.date == null && wfsd.owner != null) ||
            (index == 6 && wfsd.owner != null) ||
            existAfter.length
        ) {
          return false
        } else {
          return true
        }
      }
    }

    const isProspectingChange = () => {
      console.log(workFloLocal.value.isProspecting)
      // console.log(JSON.parse(JSON.stringify(workFloStatusDateLocal.value)))

      let leadIndex = workFloLocal.value.workFloStatusDates.findIndex((wfsd) => wfsd.workFloStatus.name == 'Lead')

      if (leadIndex != -1) {
        let leadWfsd = workFloLocal.value.workFloStatusDates[leadIndex]
        if (leadWfsd.date == null) {
          workFloLocal.value.workFloStatusDates[leadIndex].date = moment().format('YYYY-MM-DD')
          workFloStatusDateLocal.value[1].date = moment().format('YYYY-MM-DD')
        }
      } else {
        workFloLocal.value.workFloStatusDates.push({
          date: moment().format('YYYY-MM-DD'),
          workFloStatus: {
            id: 2,
            name: 'Lead'
          },
          owner: getUserData() == null ? null : getUserData().id
        })
        workFloStatusDateLocal.value[2].date = moment().format('YYYY-MM-DD')
        // workFloStatusDateLocal.value[2].workFloStatus = 2
        workFloStatusDateLocal.value[2].owner = getUserData().id
      }


      // To do : bug
      // if (workFloLocal.value.isProspecting == true) {
      //   let enQualificationIndex = workFloLocal.value.workFloStatusDates.findIndex((wfsd) => wfsd.workFloStatus.name == 'En qualification')
      //   if (enQualificationIndex != -1) {
      //     // delete workFloLocal.value.workFloStatusDates[enQualificationIndex]
      //     workFloLocal.value.workFloStatusDates[leadIndex].date = null
      //   }
      //
      //   workFloStatusDateLocal.value[2].date = null
      //   workFloStatusDateLocal.value[2].owner = null
      //
      //   workFloStatusDateLocal.value[1].date = null
      //   workFloStatusDateLocal.value[1].owner = getUserData().id
      //
      // }




      // console.log(workFloLocal.value)
      // console.log(workFloStatusDateLocal.value)
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    populateWorkFloStatusDate()

    return {
      // Components

      // Data
      workFloStatusDateLocal,
      componentKey,

      // Computed
      workFloLocal,

      // Methods
      updateWorkFloStatusDate,
      updateWorkFloStatusDateOwner,
      isDisabled,
      isProspectingChange,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>