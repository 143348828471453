<template>
  <div class="mt-2">

    <h1>{{ capitalize($t('outbound')) }}</h1>

    <b-row>
      <b-col md="6">
        <!--    source (canal de prospection) -->
        <transition name="fade">
          <input-select2
              :model.sync="workFloLocal.source"
              :options="sourceOptions"
              :display-label="false"
              reduce="value"
              name="source"
              :placeholder="capitalize($t('prospecting channel'))"
          />
        </transition>

<!--        &lt;!&ndash;    source &ndash;&gt;-->
<!--        <input-select2-->
<!--            :model.sync="workFloLocal.sourceIncomingRequest"-->
<!--            :options="sourceIncomingRequestOptions"-->
<!--            name="sourceIncomingRequest"-->
<!--            :placeholder="$t('chooseAnSource')"-->
<!--            reduce="value"-->

<!--            @input="resetMarketing"-->
<!--        />-->

        <!--    source event -->
        <input-select2
            v-if="workFloLocal.source == 'event'"
            :key="componentKey"
            :model.sync="workFloLocal.sourceEvent"
            :options="sourceEventOptions"
            name="event"
            :placeholder="$t('chooseAEvent')"
            reduce="value"
        />

      </b-col>

      <b-col md="6">
        <input-field
            name="sourceDetails"
            :model.sync="workFloLocal.sourceDetails"
        />
      </b-col>

    </b-row>

  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { capitalize } from '../../../../../utils/filter'
import InputSelect2 from '../../../../input/Select2.vue'
import i18n from '../../../../../libs/i18n'
import InputField from '../../../../input/Input.vue'

export default {
  components: { InputField, InputSelect2 },
  props: {
    workFlo: {
      type: Object,
      default: () => {}
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const sourceIncomingRequestOptions = ref([
      {
        value: 'internet research',
        _display: capitalize(i18n.t('internetResearch')),
      },
      {
        value: 'social networking',
        _display: capitalize(i18n.t('socialNetworking')),
      },
      {
        value: 'press',
        _display: capitalize(i18n.t('press/media')),
      },
      {
        value: 'networks',
        _display: capitalize(i18n.tc('network',2)),
      },
      {
        value: 'newsletter',
        _display: capitalize(i18n.t('newsletter')),
      },
      {
        value: 'event',
        _display: capitalize(i18n.tc('event')),
      },
      {
        value: 'farming',
        _display: capitalize(i18n.tc('farming')),
      },
    ].sort((a, b) => a._display < b._display ? -1 : 1))

    const sourceEventOptions = ref([
      {
        value: 'Préventica',
        _display: 'Préventica',
      },
      {
        value: 'Expoprotection',
        _display: 'Expoprotection',
      },
      {
        value: 'Cap Emploi',
        _display: 'Cap Emploi',
      },
      {
        value: 'Workshop',
        _display: 'Workshop',
      },
    ].sort((a, b) => a._display < b._display ? -1 : 1))

    const sourceOptions = ref([
      {
        value: 'reference',
        _display: capitalize(i18n.t('reference (customer, prescriber and network)')),
      },
      {
        value: 'cold call',
        _display: capitalize(i18n.t('cold call')),
      },
      {
        value: 'cold emailing',
        _display: capitalize(i18n.t('cold emailing')),
      },
      {
        value: 'network',
        _display: capitalize(i18n.tc('network')),
      },
      {
        value: 'event',
        _display: capitalize(i18n.tc('event')),
      },
      {
        value: 'farming',
        _display: capitalize(i18n.tc('farming')),
      },
    ].sort((a, b) => a._display < b._display ? -1 : 1))

    const componentKey = ref(0)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const workFloLocal = computed({
      get () {
        return props.workFlo
      },
      set (val) {
        emit('update:workFlo', val)
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const resetMarketing = () => {
      workFloLocal.value.sourcePressMedia=null
      workFloLocal.value.sourceSocialNetwork=null
      workFloLocal.value.sourceNetwork=null
      workFloLocal.value.sourceEvent=null

      componentKey.value++
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      sourceIncomingRequestOptions,
      sourceEventOptions,
      sourceOptions,
      componentKey,

      // Computed
      workFloLocal,

      // Methods
      resetMarketing,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { capitalize },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>