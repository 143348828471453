<template>
  <div>

    <base-template
        v-if="isReady"
        ref="baseTemplate"
        :title="$t('CRM')"
        :items="workFlos"
        :items-context="workFlosContext"
        :available-filters="$store.getters['workFlo/getAvailableFilters']"
        :payload.sync="payload"
        :module="module"
        :is-list-view-action-column-enabled="true"

        @tryToUpdateKanbanItem="tryToUpdateKanbanItem"
        @addItem="addWorkFlo"
        @showDetails="expandWorkFlo(item)"
    >
      <template #kanbanItemView="{item}">
        <work-flo-card
            :work-flo="item"

            @addEvent="newEvent($event)"
            @addReminder="addReminder($event)"
            @addOffer="newOffer($event)"
            @duplicate="duplicateWorkFloLocal(item)"

            @view="$router.push({name: 'WorkFlo view', params: { workFloId: item.id }})"
            @edit="editWorkFloLocal(item)"
            @expand="expandWorkFlo(item)"
            @remove="deleteWorkFloAlert(item)"
            @click="expandWorkFlo(item)"
            @middleClick="openInNewTab(item)"
            @mailMainContact="mailMainContact({mainContact: $event, workFlo: item})"
        />
      </template>

      <template #listView_cell_status_name="{item}">
        <span v-if="item.status">{{ item.status.name }}</span>
      </template>

      <template #listView_cell__lastActivity="{item}">
        <span v-if="item._lastActivity.type == null">-</span>
        <div v-else>
          <span v-if="item._lastActivity.type == 'event'">
            {{ item._lastActivity.entity.startDate|moment('LL') }}
          </span>
          <span v-else-if="item._lastActivity.type == 'reminder'">
            {{ item._lastActivity.entity.completionDate|moment('LL') }}
          </span>
        </div>
      </template>

      <template #listView_cell__lastEvent="{item}">
        <span v-if="item._lastEvent == null">-</span>
        <div v-else>
          {{ item._lastEvent.startDate|moment('LL') }}
        </div>
      </template>

      <template #listView_cell__lastReminder="{item}">
        <span v-if="item._lastReminder == null">-</span>
        <div v-else>
          {{ item._lastReminder.startDate|moment('LL') }}
        </div>
      </template>

      <template #listView_cell_source="{item}">
        <span v-if="item.source">{{ capitalize($t(item.source)) }}</span>
        <!--        <span v-else>-</span>-->
      </template>

      <template #listView_cell_workFloUsages="{item}">
        <span v-if="item.workFloUsages">{{ getUsages(item.workFloUsages) }}</span>
        <!--        <span v-else>-</span>-->
      </template>

      <template #listView_cell_isPriority="{item}">
        <span v-if="item.isPriority == true">{{ capitalize($t('true')) }}</span>
        <span v-else>{{ capitalize($t('false')) }}</span>
      </template>

      <template #listView_cell_communication="{item}">
        <span v-if="item.communication == true">{{ capitalize($t('true')) }}</span>
        <span v-else>{{ capitalize($t('false')) }}</span>
      </template>

      <template #listView_cell_state="{item}">
        <span>{{ capitalize($t(item.state)) }}</span>
      </template>

      <!--      <template #listView_cell_sourceIncomingRequest="{item}">-->
      <!--        <span>{{ capitalize($t(item.sourceIncomingRequest)) }}</span>-->
      <!--      </template>-->

      <template #listView_cell_sector="{item}">
        <span>{{ capitalize($t(item.sector)) }}</span>
      </template>

      <template #listView_cell_businessSector="{item}">
        <span>{{ capitalize($t(item.businessSector)) }}</span>
      </template>

      <template #listView_cell_sourcePressMedia="{item}">
        <span>{{ capitalize($t(item.sourcePressMedia)) }}</span>
      </template>

      <template #listView_cell_isProspecting="{item}">
        <span v-if="item.isProspecting == true">{{ capitalize($t('true')) }}</span>
        <span v-else>{{ capitalize($t('false')) }}</span>
      </template>

      <template #listView_cell_sourceNetwork="{item}">
        <span>{{ capitalize($t(item.sourceNetwork)) }}</span>
      </template>

      <template #listView_cell_actions="{item}">
        <!--          Edit -->
        <button-edit
            @click.native.stop="editWorkFloLocal(item)"
            v-b-tooltip.hover.left="$t('Edit')"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
        />

        <!--          Delete-->
        <button-remove
            v-b-tooltip.hover.left="capitalize($t('delete'))"
            @click.native.stop="deleteWorkFloAlert(item)"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
        />

      </template>
    </base-template>

    <!-- modal workFlo -->
    <modal-work-flo
        :work-flo.sync="currentWorkFlo"
        :title="('id' in currentWorkFlo)?(currentWorkFlo.id + ' - ' +$t('EditWorkFlo')):$t('NewWorkFlo')"
        :isOpen.sync="workFloModalShow"
        :work-flo-modal-default-tab="workFloModalDefaultTab"
        @submitValidated="submitValidatedWorkFloLocal"
    />

    <!-- modal event-->
    <modal-event
        :event.sync="currentEvent"
        :title="eventModalTitle"
        :isOpen.sync="eventModalShow"
        :view-only="!isEventEditable"
        @submitValidatedEvent="submitValidatedEventLocal();createReminder(currentEvent)"
        @removeEvent="removeEventAlert"
    />

    <!-- modal reminder-->
    <reminder-modal
        :reminder.sync="currentReminder"
        :title="reminderModalTitle"
        :isOpen.sync="reminderModalShow"

        @submitValidatedReminder="submitValidatedReminderLocal"
        @removeReminder="removeReminderAlert"
    />

    <!-- modal offer -->
    <modal-offer
        :offer.sync="currentOffer"
        :title="('id' in currentOffer)?$t('EditOffer'):$t('NewOffer')"
        :is-open.sync="offerModalShow"
        @submitValidatedOffer="submitValidatedOfferLocal"
    />

    <!-- workFlo popup -->
    <work-flo-popup
        :work-flo.sync="currentWorkFlo"
        :is-open.sync="workFloPopupShow"
        @submitValidated="submitValidatedWorkFloLocal"

        @view="$router.push({name: 'WorkFlo view', params: { workFloId: currentWorkFlo.id }}); workFloPopupShow=false"
        @edit="editWorkFloLocal(currentWorkFlo, $event)"
        @addEvent="newEvent(); workFloPopupShow=false"
    />

    <!-- email popup -->
    <email-prompt
        :email.sync="currentEmail"
        :is-open.sync="emailPopupShow"

        @submitValidated="submitValidatedEmailLocal"
    />

    <!-- modal event after mail-->
    <modal-event
        :event.sync="currentEventLocal"
        :title="$t('NewEvent')"
        :isOpen.sync="eventModalLocalShow"

        @submitValidatedEvent="submitValidatedEventLocal2"
    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize }           from '../../utils/filter'
import { useWorkFlos }          from '../../views/crm/useWorkFlos'

import useAPI from '../../utils/useAPI'
import store  from '../../store'

import BaseTemplate           from '../base3/Base.vue'
import Ripple                 from 'vue-ripple-directive'
// import WorkFloCard  from '../card/WorkFlo.vue'
import WorkFloCard            from '../card/WorkFloCard.vue'
import ButtonRemove           from '../button/Remove.vue'
import ButtonView             from '../button/View.vue'
import ButtonEdit             from '../button/Edit.vue'
import ModalWorkFlo           from '../prompt/WorkFlo.vue'
import router                 from '../../router'
import { clone, getUserData } from '../../utils/utils'
import { mixinEvents }        from '../../mixins/mixinEvents'
import ModalEvent             from '../prompt/Event.vue'
import moment                 from 'moment'
import { mixinOffers }        from '../../mixins/mixinOffers'
import ModalOffer             from '../prompt/Offer.vue'
import WorkFloPopup           from '../../views/crm/components/popup/WorkFloPopup.vue'
import EmailPrompt            from '../prompt/EmailPrompt.vue'
import { useEmails }          from '../email/v2/useEmails'
import { useEvents }          from '../eventsCalendar/useEvents'
import { mixinReminders }     from '../../mixins/mixinReminders'
import ReminderModal          from '../prompt/ReminderModal.vue'
import Vue                    from 'vue'
import ToastificationContent  from '../toastification/ToastificationContent.vue'
import i18n                   from '../../libs/i18n'

export default {
  directives: {
    Ripple,
  },
  mixins: [mixinEvents, mixinOffers, mixinReminders],
  components: {
    ReminderModal,
    EmailPrompt,
    WorkFloPopup,
    ModalOffer,
    ModalEvent,
    ModalWorkFlo,
    ButtonEdit,
    ButtonView,
    ButtonRemove,
    WorkFloCard,
    BaseTemplate
  },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const baseTemplate = ref()
    const module = ref('crm')
    const previousPayload = ref({})
    const payload = ref({})
    const isReady = ref(false)
    const isAutocompleteLoaded = ref(false)

    const currentWorkFlo = ref({})
    const workFloModalShow = ref(false)
    const workFloModalDefaultTab = ref(null)
    const workFloPopupShow = ref(false)
    const currentEmail = ref(null)
    const emailPopupShow = ref(false)
    const currentEventLocal = ref({})
    const eventModalLocalShow = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { workFlos, workFlosContext } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(payload, val => {
      // console.log(previousPayload.value)
      // console.log(clone(val))

      let valTmp = clone(val)

      if (
          'page' in previousPayload.value &&
          previousPayload.value.page == val.page
      ) {
        store.commit('workFlo/RESET_WORKFLOS')
        valTmp.page = 1
      }

      isReady.value = true
      fetchWorkFlos(valTmp)
          .then(() => {
            previousPayload.value = clone(valTmp)
            if (!isAutocompleteLoaded.value) {
              loadInit()
            }
          })
    }, { deep: true })

    watch(workFloModalShow, val => {
      if (val == false) {
        // resetWorkFlo()
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      fetchWorkFlos,
      fetchAutocompleteUsers,
      fetchAutocompleteEmployees,
      fetchAutocompleteCompanies,
      fetchAutocompleteContacts,
      fetchAutocompleteArticles,
      fetchAutocompleteWorkFlos,
      fetchAutocompleteWorkFloStatuss,
      fetchAutocompleteWorkFloUsages,
      fetchAutocompletePaymentMethods,
      fetchAutocompletePaymentTerms,
      fetchAutocompleteVats,
    } = useAPI()

    const { sendEmail } = useEmails()

    const { submitValidatedWorkFlo, removeWorkFlo } = useWorkFlos()

    const { submitValidatedEvent, eventToReminder } = useEvents()

    const loadInit = () => {
      resetWorkFlo()
      fetchAutocompleteUsers()
      fetchAutocompleteEmployees()
      fetchAutocompleteCompanies()
      fetchAutocompleteContacts()
      fetchAutocompleteArticles()
      fetchAutocompleteWorkFlos()
      fetchAutocompleteWorkFloStatuss()
      fetchAutocompleteWorkFloUsages()
      fetchAutocompletePaymentMethods()
      fetchAutocompletePaymentTerms()
      fetchAutocompleteVats()

      store.dispatch('event3/getEvents', {
        // filters: dateFilter
        filters: [
          {
            sectionLogicOperator: 'AND',
            sectionFilters: [
              {
                logicOperator: 'AND',
                field: 'startDate',
                operator: 'equal',
                value: moment().format('YYYY-MM-DD')
              },
            ]
          },
          {
            sectionLogicOperator: 'AND',
            sectionFilters: [
              {
                logicOperator: 'OR',
                field: 'createdBy',
                operator: 'equal',
                value: getUserData().id
              }, {
                logicOperator: 'OR',
                field: 'additionalMembers',
                operator: 'equal',
                value: getUserData().id
              },
            ]
          },
        ],
        per_page: 5000
      })

      isAutocompleteLoaded.value = true
    }

    const addWorkFlo = () => {
      resetWorkFlo()
      workFloModalShow.value = true
    }

    const tryToUpdateKanbanItem = (tryToUpdateKanbanItem) => {
      store.dispatch('workFlo/getWorkFlo', tryToUpdateKanbanItem.not_updated_object.id)
          .then((response) => {
            currentWorkFlo.value = response
            currentWorkFlo.value.status = store.getters['workFloStatus/getWorkFloStatusByName'](tryToUpdateKanbanItem.new_column_object_title)

            submitValidatedWorkFloLocal()
          })
    }

    const resetWorkFlo = () => {
      currentWorkFlo.value = JSON.parse(JSON.stringify(store.getters['workFlo/getEmptyWorkFlo']))
      workFloModalDefaultTab.value = null
    }

    const editWorkFloLocal = (workFlo, { defaultTab } = {}) => {
      if (defaultTab !== undefined) {
        workFloModalDefaultTab.value = defaultTab
      }

      store.dispatch('workFlo/getWorkFlo', workFlo.id)
          .then(response => {
            workFloPopupShow.value = false

            // console.log(response)
            currentWorkFlo.value = response.data
            workFloModalShow.value = true
          })
    }

    const duplicateWorkFloLocal = (workFlo) => {
      store.dispatch('workFlo/getWorkFlo', workFlo.id)
          .then(response => {
            currentWorkFlo.value = clone(response.data)

            delete currentWorkFlo.value.id
            delete currentWorkFlo.value.logs
            currentWorkFlo.value.workFloTasks = []
            currentWorkFlo.value.workFloNotes = []
            currentWorkFlo.value.workFloStatusDates = []
            currentWorkFlo.value.workFloNeeds = []

            response.data.workFloStatusDates.forEach(wfsd => {
              let wfsdTmp = clone(wfsd)
              delete wfsdTmp.id
              currentWorkFlo.value.workFloStatusDates.push(wfsdTmp)
            })

            response.data.workFloNeeds.forEach(wfn => {
              let wfnTmp = clone(wfn)
              delete wfnTmp.id
              currentWorkFlo.value.workFloNeeds.push(wfnTmp)
            })

            workFloModalShow.value = true
          })
    }

    const removeWorkFloLocal = (workFlo) => {
      removeWorkFlo(workFlo)
          .then(response => {

          })
    }

    const submitValidatedWorkFloLocal = () => {
      submitValidatedWorkFlo(currentWorkFlo.value)
          .then(response => {
            workFloModalShow.value = false
          })
    }

    const getUsages = (workFloUsages) => {
      return workFloUsages.map(a => a.name).join(', ')
    }

    const openInNewTab = item => {
      window.open(router.resolve({ name: 'WorkFlo view', params: { workFloId: item.id } }).href, '_blank')
    }

    const expandWorkFlo = workFlo => {
      store.dispatch('workFlo/getWorkFlo', workFlo.id)
          .then(response => {
            // console.log(response)
            currentWorkFlo.value = response.data
            workFloPopupShow.value = true
          })
    }

    const mailMainContact = ({mainContact, workFlo}) => {
      currentEmail.value = clone(store.getters['email/getEmptyEmail'])
      currentEmail.value.toRecipients = [{
        emailAddress: {
          name: '',
          address: mainContact
        }
      }]

      currentEventLocal.value = clone(store.getters['event3/getEmptyEvent'])
      currentEventLocal.value.type = 'mail'
      currentEventLocal.value.startDate = moment()
      currentEventLocal.value.workFlo = workFlo

      emailPopupShow.value = true
    }

    const submitValidatedEmailLocal = () => {
      sendEmail(currentEmail.value)
          .then(response => {
            currentEventLocal.value.title = currentEmail.value.subject
            currentEventLocal.value.endDate = moment()
            eventModalLocalShow.value = true

            // console.log(clone(currentEventLocal.value))

            currentEmail.value = clone(store.getters['email/getEmptyEmail'])
          })
    }

    const submitValidatedEventLocal2 = () => {
      submitValidatedEvent(currentEvent.value)
          .then(response => {
            eventModalLocalShow.value = false
          })
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    resetWorkFlo()
    store.dispatch('moduleView/getModuleViews', {
      per_page: 5000
    })
        .then((response) => {
          let currentView = store.getters['moduleView/getCurrentModuleView'](module.value)
// console.log(currentView)
          payload.value = {
            filters: currentView.filters,
            kanbanSortBy: currentView.kanbanSortBy,
            kanbanColumnAmount: currentView.kanbanColumnAmount,
            page: 1,
            sorts: currentView.sorts,
          }

        })

    return {
      // Components
      capitalize,

      // Data
      baseTemplate,
      module,
      payload,
      isReady,

      currentWorkFlo,
      workFloModalShow,
      workFloModalDefaultTab,
      workFloPopupShow,
      currentEmail,
      emailPopupShow,
      currentEventLocal,
      eventModalLocalShow,

      // Computed
      workFlos,
      workFlosContext,

      // Methods
      tryToUpdateKanbanItem,
      editWorkFloLocal,
      duplicateWorkFloLocal,
      removeWorkFloLocal,
      submitValidatedWorkFloLocal,
      getUsages,
      resetWorkFlo,
      addWorkFlo,
      openInNewTab,
      expandWorkFlo,
      mailMainContact,
      submitValidatedEmailLocal,
      submitValidatedEventLocal2,
      eventToReminder,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    deleteWorkFloAlert (workFlo) {
      this.$bvModal
          .msgBoxConfirm(this.$t('DeleteAlert', { msg: this.$t('theWorkFlo') }), {
            okVariant: 'danger',
            okTitle: this.capitalize(this.$t('delete')),
            cancelVariant: 'outline-secondary',
            cancelTitle: this.capitalize(this.$t('cancel')),
            centered: true,
          })
          .then(value => {
            if (value == true) {
              this.removeWorkFloLocal(workFlo)
            }
          })
    },
    createReminder (event) {
      let reminder = null
      if (
          ('_reminderPeriod' in event) &&
          event._reminderPeriod != null
      ) {
        reminder = this.eventToReminder(event)

        delete reminder.closeReminder

        this.addReminder(reminder)
      }
    }
  },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>