import store from '@/store'

export const useReminders = () => {
  const submitValidatedReminder = (reminder) => {
    return new Promise((resolve, reject) => {
      if ('id' in reminder) {
        updateReminder(reminder)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      } else {
        saveNewReminder(reminder)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      }
    })
  }

  const saveNewReminder = (reminder) => {
    return new Promise((resolve, reject) => {
      store.dispatch('reminder/addReminder', reminder)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const updateReminder = (reminder) => {
    return new Promise((resolve, reject) => {
      store.dispatch('reminder/updateReminder', reminder)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const removeReminder = (reminder) => {
    return new Promise((resolve, reject) => {
      store.dispatch('reminder/removeReminder', reminder)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  return {
    submitValidatedReminder,
    removeReminder,
  }
}