import store from '@/store'
import { computed } from '@vue/composition-api'
import { getRoute } from '../../../utils/utils'

export const useOffers = () => {

  const submitValidatedOffer = (offer) => {
    return new Promise((resolve, reject) => {
      if ('id' in offer) {
        updateOffer(offer)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      } else {
        saveNewOffer(offer)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      }
    })
  }

  const saveNewOffer = (offer) => {
    return new Promise((resolve, reject) => {
      store.dispatch('offer2/addOffer', offer)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const updateOffer = (offer) => {
    return new Promise((resolve, reject) => {
      store.dispatch('offer2/updateOffer', offer)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const removeOffer = (offer) => {
    return new Promise((resolve, reject) => {
      store.dispatch('offer2/removeOffer', offer)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const transformProductsToOutgoingInvoice = (offer, selectedProducts) => {
    if (getRoute().name == 'Workflow view') {
      let workflow = store.getters['workflow/getWorkflow'](getRoute().params.workflowId)
      offer.workflow = workflow.id
    }

    if (getRoute().name == 'WorkFlo view') {
      // console.log(getRoute().params)

      // let workFlo = store.getters['workFlo/getWorkFlo'](getRoute().params.workFloId)
      offer.workFlo = getRoute().params.workFloId
    }

    return new Promise((resolve, reject) => {
      store.dispatch('offer2/transformProductsToOutgoingInvoice', {
        offer: offer,
        selectedProducts: selectedProducts
      })
        .then(response => {
          offer._filters[7].value = false

          if (offer.preTaxAmount == response.data.preTaxAmount) {
            offer._filters[9].value = true
          } else {
            offer._filters[8].value = true
          }

          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const downloadOfferQuote = (offer) => {
    let link = document.createElement('a')
    link.href = offer.quotes.at(-1).url
    link.target = '_blank'
    link.download = offer.quotes.at(-1).name + '.' + offer.quotes.at(-1).extension
    link.click()
  }

  return {
    submitValidatedOffer,
    removeOffer,
    transformProductsToOutgoingInvoice,
    downloadOfferQuote,
  }
}