<template>
  <div>

    <b-row>

      <!--      Title-->
      <b-col cols="12">
        <input-field
            name="title"
            :model.sync="workFloLocal.title"
            rules="required"
        />
      </b-col>

      <b-col md="6">
        <internal-management
            :work-flo.sync="workFloLocal"
        />
      </b-col>

      <b-col md="6">
        <customer
            :work-flo.sync="workFloLocal"

            @companyUpdated="companyUpdated"
        />
      </b-col>

      <b-col md="12">
        <field-select
            :model.sync="workFloLocal.mainAddress"
            name="mainAddress"
            :options="listOfAddresses"
            :label="'_display'"
            :placeholder="$t('SelectMainAddress')"
        />
      </b-col>
    </b-row>

    <commercial
        :work-flo.sync="workFloLocal"

        @isProspectingChange="$emit('isProspectingChange')"
    />

    <transition name="fade">
      <!--      <marketing-->
      <!--          v-if="displayMarketing"-->
      <!--          :work-flo.sync="workFloLocal"-->
      <!--      />-->

      <work-flo-general-inbound
          v-if="displayInbound"
          :work-flo.sync="workFloLocal"
      />
      <work-flo-general-outbound
          v-if="displayOutbound"
          :work-flo.sync="workFloLocal"
      />
    </transition>

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize }           from '../../../../utils/filter'
import { isObject }             from '../../../../utils/utils'

import useAPI from '../../../../utils/useAPI'
import i18n   from '../../../../libs/i18n'
import store  from '../../../../store'

import InputTextarea          from '../../../input/Textarea.vue'
import InputSwitch            from '../../../input/Switch.vue'
import InputDate              from '../../../input/Date.vue'
import InputContact           from '../../../input/Contact2.vue'
import InputField             from '../../../input/Input.vue'
import InputSelect2           from '../../../input/Select2.vue'
import InternalManagement     from './workFloGeneral/_workFloGeneralInternalManagement.vue'
import Customer               from './workFloGeneral/_workFloGeneralCustomer.vue'
import Commercial             from './workFloGeneral/_workFloGeneralCommercial.vue'
import Marketing              from './workFloGeneral/_workFloGeneralMarketing.vue'
import Collapse               from '../../../collapse/Collapse.vue'
import CollapseItem           from '../../../collapse/CollapseItem.vue'
import WorkFloGeneralInbound  from './workFloGeneral/_workFloGeneralInbound.vue'
import WorkFloGeneralOutbound from './workFloGeneral/_workFloGeneralOutbound.vue'
import FieldSelect            from '../../../input/Select.vue'

export default {
  components: {
    FieldSelect,
    WorkFloGeneralInbound,
    WorkFloGeneralOutbound,
    InputContact,
    InputDate,
    InputSwitch,
    InputTextarea,
    InputSelect2,
    InputField,
    InternalManagement,
    Customer,
    Commercial,
    Marketing,
    Collapse,
    CollapseItem
  },
  props: {
    workFlo: {
      type: Object,
      default: () => {}
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const sourceOptions = ref([
      {
        value: 'reference/prescriber/networks',
        display: capitalize(i18n.t('reference/prescriber/networks')),
      },
      {
        value: 'prospecting/client follow-up',
        display: capitalize(i18n.t('prospecting/client follow-up')),
      },
      {
        value: 'exhibition/event',
        display: capitalize(i18n.t('exhibition/event')),
      },
      {
        value: 'incoming request',
        display: capitalize(i18n.t('incoming request')),
      },
      {
        value: 'AA/distributor',
        display: capitalize(i18n.t('AA/distributor')),
      },
    ])
    const sourceIncomingRequestOptions = ref([
      {
        value: 'press',
        display: capitalize(i18n.t('press')),
      },
      {
        value: 'internet research',
        display: capitalize(i18n.t('internetResearch')),
      },
      {
        value: 'social networking',
        display: capitalize(i18n.t('socialNetworking')),
      },
    ])
    const displaySourceIncomingRequestDetails = ref(false)
    const displaySourceExhibitionDetails = ref(false)
    const listOfContacts = ref([])
    const listOfAddresses = ref([])
    const exhibitionOptions = ref([
      'Expoprotection',
      'Journée CAP Emploi',
      'Préventica',
      'WorkShop',
    ])
    const typeOfSaleOptions = ref([
      'VI',
      'VD',
      'Autre'
    ])
    const functionOptions = ref([
      'Prévention',
      'RQTH',
      'Prévention et RQTH'
    ])
    const typeOfApplicationOptions = ref([
      'Innovation',
      'Événement',
      'Appel d\'offres',
      'Normal'
    ])
    const sectorOptions = ref([
      {
        value: 'maintenance',
        display: capitalize(i18n.t('maintenance')),
      },
      {
        value: 'food-processing',
        display: capitalize(i18n.t('food-processing')),
      },
      {
        value: 'industry',
        display: capitalize(i18n.t('industry')),
      },
      {
        value: 'logistics',
        display: capitalize(i18n.t('logistics')),
      },
      {
        value: 'construction industry',
        display: capitalize(i18n.t('constructionIndustry')),
      },
      {
        value: 'health',
        display: capitalize(i18n.t('health')),
      },
    ])
    const distributionTypeOptions = ref([
      'Distributeur autonomie (III)',
      'Distributeur novice (II)',
      'Apport d\'affaires (I)'
    ])
    const typeOfPurchaseOptions = ref([
      'Achat post location',
      'Location',
      'Achat récurrent',
      'Premier achat'
    ])

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const {
      autocompleteEmployees,
      autocompleteWorkFloStatus,
      autocompleteCompanies,
    } = useAPI()

    const workFloLocal = computed({
      get () {
        return props.workFlo
      },
      set (val) {
        emit('update:workFlo', val)
      }
    })

    const listOfOthersContacts = computed(() => {
      return (listOfContacts.value.filter(c => c.id != (workFloLocal.value.mainContact ? workFloLocal.value.mainContact.id : null)))
    })

    const stateOptions = computed(() => {
      let output = []
      if (workFloLocal.value.typeOfSale != null) {
        if (workFloLocal.value.typeOfSale != 'VI') {
          output = [
            {
              display: capitalize(i18n.t('inProgress')),
              value: 'in progress'
            },
            {
              display: capitalize(i18n.t('success')),
              value: 'success'
            },
            {
              display: capitalize(i18n.t('lost')),
              value: 'lost'
            },
            {
              display: capitalize(i18n.t('refused')),
              value: 'refused'
            },
            {
              display: capitalize(i18n.t('abandoned')),
              value: 'abandoned'
            },
            {
              display: capitalize(i18n.t('transferred')),
              value: 'transferred'
            },
            {
              display: capitalize(i18n.t('inConflict')),
              value: 'in conflict'
            },
            {
              display: capitalize(i18n.t('dropped')),
              value: 'dropped'
            }
          ]
        } else {
          output = [
            {
              display: capitalize(i18n.t('success')),
              value: 'success'
            },
            {
              display: capitalize(i18n.t('failure')),
              value: 'failure'
            },
            {
              display: capitalize(i18n.t('inProgress')),
              value: 'in progress'
            },
            {
              display: capitalize(i18n.t('lost')),
              value: 'lost'
            }
          ]
        }
      }
      return output
    })

    // const displayMarketing = computed(() => {
    //   return (
    //       workFloLocal.value.typeOfPurchase != 'Achat récurrent' &&
    //       workFloLocal.value.typeOfPurchase != 'Achat post location' &&
    //       workFloLocal.value.isProspecting == false
    //
    //   )
    // })
    const displayInbound = computed(() => {
      return (
          workFloLocal.value.typeOfPurchase != 'Achat récurrent' &&
          workFloLocal.value.typeOfPurchase != 'Achat post location' &&
          workFloLocal.value.isProspecting == false
      )
    })
    const displayOutbound = computed(() => {
      if (
          workFloLocal.value.isProspecting == false &&
          workFloLocal.value.typeOfPurchase == 'Achat récurrent'
      ) {
        return false
      } else {
        return !displayInbound.value
      }
    })

    // const addresses = computed(() => {
    //   console.log(workFloLocal.value.company)
    //
    //   if (workFloLocal.value.company !== undefined) {
    //     store.dispatch('company/getCompany', workFloLocal.value.company.id)
    //         .then(response => {
    //           console.log(response)
    //         })
    //   }
    // })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    // watch(workFloLocal, (newValue, oldValue) => {
    //   console.log(JSON.parse(JSON.stringify(newValue.source)))
    //   console.log(JSON.parse(JSON.stringify(oldValue.source)))
    //   // if (JSON.stringify(newValue) != JSON.stringify(oldValue)) {
    //   //   populateDisplaySourceIncomingRequestDetails(newValue)
    //   //   populateDisplaySourceExhibitionDetails(newValue)
    //   // }
    // }, { deep: true })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const populateDisplaySourceIncomingRequestDetails = (wf) => {
      if (
          wf.source != null &&
          (
              isObject(wf.source) &&
              wf.source.value == 'incoming request'
          ) ||
          wf.source == 'incoming request'
      ) {
        displaySourceIncomingRequestDetails.value = true
      } else {
        displaySourceIncomingRequestDetails.value = false
      }
    }

    const populateDisplaySourceExhibitionDetails = (wf) => {
      if (
          wf.source != null &&
          (
              isObject(wf.source) &&
              wf.source.value == 'exhibition/event'
          ) ||
          wf.source == 'exhibition/event'
      ) {
        displaySourceExhibitionDetails.value = true
      } else {
        displaySourceExhibitionDetails.value = false
      }
    }

    const updateContacts = () => {
      // console.log(workFloLocal.value)

      if (workFloLocal.value && ('company' in workFloLocal.value) && workFloLocal.value.company != undefined) {

        store.dispatch('user2/getAutocompleteUsers', {
          filters: [
            {
              sectionLogicOperator: 'AND',
              sectionFilters: [
                {
                  logicOperator: 'AND',
                  field: 'company',
                  operator: 'equal',
                  value: isObject(workFloLocal.value.company) ? workFloLocal.value.company.id : workFloLocal.value.company
                }
              ]
            }
          ]
        }).then(response => {
          // console.log(response)
          listOfContacts.value = response.results
        })

      }

    }

    const mainContactUpdated = () => {
      workFloLocal.value.otherContacts = []
    }

    const companyUpdated = () => {
      // console.log(workFloLocal.value.company)

      if (workFloLocal.value.company == null) {
        listOfAddresses.value = []
      } else {
        store.dispatch('company/getCompany', workFloLocal.value.company.id)
            .then(response => {
              listOfAddresses.value = response.addresses
            })
      }

    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    populateDisplaySourceIncomingRequestDetails(props.workFlo)
    populateDisplaySourceExhibitionDetails(props.workFlo)
    updateContacts()
    companyUpdated()

    return {
      // Components
      capitalize,

      // Data
      sourceOptions,
      sourceIncomingRequestOptions,
      displaySourceIncomingRequestDetails,
      displaySourceExhibitionDetails,
      listOfContacts,
      listOfAddresses,
      exhibitionOptions,
      typeOfSaleOptions,
      functionOptions,
      typeOfApplicationOptions,
      sectorOptions,
      distributionTypeOptions,
      typeOfPurchaseOptions,

      // Computed
      autocompleteEmployees,
      autocompleteWorkFloStatus,
      autocompleteCompanies,
      workFloLocal,
      listOfOthersContacts,
      stateOptions,
      // displayMarketing,
      displayInbound,
      displayOutbound,

      // Methods
      updateContacts,
      mainContactUpdated,
      populateDisplaySourceIncomingRequestDetails,
      populateDisplaySourceExhibitionDetails,
      companyUpdated,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    lang="scss"
    scoped
>

</style>