<template>
  <div>
    <h1>{{ capitalize($t('internalManagement')) }}</h1>

    <b-row>

      <!--    assignment (affectation)-->
      <b-col md="12">
        <input-select2
            :model.sync="workFloLocal.assignment"
            :options="autocompleteEmployees"
            :is-required="true"
            name="assignment"
            :display-label="false"
            :placeholder="$t('assignment')"
            :disabled="true"
        />
      </b-col>

      <!--    status-->
      <b-col md="12">
        <input-select2
            name="status"
            :model.sync="workFloLocal.status"
            :options="autocompleteWorkFloStatus"
            :is-required="true"
            label="name"
            :display-label="false"
            :placeholder="capitalize($t('status'))"
            :disabled="true"
        />
      </b-col>

      <!--          Business sector-->
      <b-col md="12">
        <input-select2
            :model.sync="workFloLocal.businessSector"
            :options="businessSectorOptions"
            name="businessSector"
            :display-label="false"
            :placeholder="$t('businessSector')"
            reduce="value"

        />
      </b-col>

      <!--          Business other-->
      <b-col md="12">
        <input-field
            name="other"
            :display-label="false"
            :placeholder="$t('other')"
            :model.sync="workFloLocal.businessSectorOther"
        />
      </b-col>


    </b-row>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '../../../../../utils/filter'

import useAPI from '../../../../../utils/useAPI'
import i18n from '../../../../../libs/i18n'

import InputSelect2 from '../../../../input/Select2.vue'
import InputField from '../../../../input/Input.vue'
import InputSwitch  from '../../../../input/Switch.vue'

export default {
  components: { InputSwitch, InputField, InputSelect2 },
  props: {
    workFlo: {
      type: Object,
      default: () => {}
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const businessSectorOptions = ref([
      {
        value: 'industry',
        _display: capitalize(i18n.t('industry')),
      },
      {
        value: 'maintenance/servicing',
        _display: capitalize(i18n.t('maintenance/servicing')),
      },
      {
        value: 'agriculture/agri-food',
        _display: capitalize(i18n.t('agriculture/agri-food')),
      },
      {
        value: 'public works buildings',
        _display: capitalize(i18n.t('public works buildings')),
      },
      {
        value: 'logistics',
        _display: capitalize(i18n.t('logistics')),
      },
      {
        value: 'health',
        _display: capitalize(i18n.t('health')),
      },
      {
        value: 'other',
        _display: capitalize(i18n.t('other')),
      },
    ])

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { autocompleteEmployees, autocompleteWorkFloStatus } = useAPI()

    const workFloLocal = computed({
      get () {
        return props.workFlo
      },
      set (val) {
        emit('update:workFlo', val)
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data
      businessSectorOptions,

      // Computed
      autocompleteEmployees,
      autocompleteWorkFloStatus,
      workFloLocal,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>