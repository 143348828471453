import { ref, computed, watch } from '@vue/composition-api'
import { clone, getUserData }   from '../utils/utils'
import store                    from '../store'
import i18n                     from '../libs/i18n'
import moment                   from 'moment'
import Vue                      from 'vue'
import ToastificationContent    from '../components/toastification/ToastificationContent.vue'
import { capitalize }           from '../utils/filter'
import { useEvents }            from '../components/eventsCalendar/useEvents'

export const mixinEvents = {
  components: {},
  props: {},
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const currentEvent = ref({})
    const eventModalShow = ref(false)
    const eventModalTitle = ref(i18n.t('NewEvent'))

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const isEventEditable = computed(() => {
      return (
          currentEvent.value.isValidated != true
        ) &&
        (
          (
            !('id' in currentEvent.value)
          ) ||
          (
            'logs' in currentEvent.value &&
            currentEvent.value.logs.length >= 1 &&
            currentEvent.value.logs[0].by.id == getUserData().id
          )
        )
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { eventToReminder, submitValidatedEvent, removeEvent } = useEvents()
    const resetEvent = () => {
      currentEvent.value = clone(store.getters['event3/getEmptyEvent'])
      eventModalTitle.value = i18n.t('NewEvent')
    }

    const newEvent = (preLoadedEvent = null) => {
      // console.log("ok")
      if (preLoadedEvent == null) {
        resetEvent()

        currentEvent.value.startDate = moment()
        currentEvent.value.endDate = moment().add(30, 'minutes')
      } else {
        currentEvent.value = preLoadedEvent
      }

      eventModalShow.value = true
    }

    const submitValidatedEventLocal = (eventsListToUpdate = null, remindersListToUpdate = null) => {
      // console.log("1")
      if (isEventEditable.value == true) {
        // console.log(clone(currentEvent.value))

        let reminder = null
        if (
          ('_reminderPeriod' in currentEvent.value) &&
          currentEvent.value._reminderPeriod != null
        ) {
          reminder = eventToReminder(currentEvent.value)

          delete reminder.closeReminder
        }

        // console.log(clone(currentEvent.value))
        submitValidatedEvent(currentEvent.value)
          .then(response => {
            if (eventsListToUpdate != null) {
              if ('id' in currentEvent.value) {
                let index = eventsListToUpdate.findIndex(i => i.id == response.data._calendarEvent.id)
                eventsListToUpdate.splice(index, 1, JSON.parse(JSON.stringify(response.data._calendarEvent)))
              } else {
                eventsListToUpdate.push(response.data._calendarEvent)
              }

              if (
                ('closeReminder' in currentEvent.value) &&
                currentEvent.value.closeReminder == true &&
                currentEvent.value.reminder != null &&
                remindersListToUpdate != null
              ) {
                remindersListToUpdate = remindersListToUpdate.filter(item => item.id != response.data.reminder.id)
              }

            } else {
              if (
                ('closeReminder' in currentEvent.value) &&
                currentEvent.value.closeReminder == true &&
                currentEvent.value.reminder != null
              ) {
                store.commit('reminder/REMOVE_REMINDER', response.data.reminder)
              }
            }

            if (reminder != null) {
              emit('addReminder', reminder)
            }

            updateCalendar()

            eventModalShow.value = false
            resetEvent()
          })
      } else {
        Vue.$toast({
          component: ToastificationContent, props: {
            title: capitalize(i18n.t('error')),
            icon: 'times',
            text: i18n.t('You are not authorized to perform this action'),
            variant: 'danger'
          },
        })
      }
    }

    const removeEventLocal = (eventsListToUpdate = null) => {
      removeEvent(currentEvent.value)
        .then(response => {
          if (eventsListToUpdate != null) {
            eventsListToUpdate = leventsListToUpdate.filter(item => item.id != currentEvent.value.id)
          }

          eventModalShow.value = false
          updateCalendar()
          resetEvent()
        })
    }

    const updateCalendar = () => {
      if (typeof calendarRef !== "undefined" ) {
        calendarRef.value.refetchEvents()
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      currentEvent,
      eventModalShow,
      eventModalTitle,

      // Computed
      isEventEditable,

      // Methods
      newEvent,
      submitValidatedEventLocal,
      removeEventLocal,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    removeEventAlert (event = null, eventsListToUpdate = null) {
      if (event != null) {
        this.currentEvent = event
      }

      this.$bvModal
        .msgBoxConfirm(this.$t('DeleteAlert', { msg: this.$t('theEvent') }), {
          okVariant: 'danger',
          okTitle: this.capitalize(this.$t('delete')),
          cancelVariant: 'outline-secondary',
          cancelTitle: this.capitalize(this.$t('cancel')),
          centered: true,
        })
        .then(value => {
          if (value == true) {
            this.removeEventLocal(eventsListToUpdate)
          }
        })
    },
  },
  mounted () {
  },
  created () {
  }
}