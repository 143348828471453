import { ref, computed, watch } from '@vue/composition-api'
import { clone, getUserData }   from '../utils/utils'
import store                    from '../store'
import i18n                     from '../libs/i18n'
import { useOffers }            from '../views/sales/offers2/useOffers'

export const mixinOffers = {
  components: {},
  props: {},
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const currentOffer = ref({})
    const offerModalShow = ref(false)
    const offerModalTitle = ref(i18n.t('NewOffer'))

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { submitValidatedOffer, removeOffer } = useOffers()
    const resetOffer = () => {
      currentOffer.value = clone(store.getters['offer2/getEmptyOffer'])
      offerModalTitle.value = i18n.t('NewOffer')
    }

    const newOffer = (preLoadedOffer = null) => {
      if (preLoadedOffer == null) {
        resetOffer()
      } else {
        currentOffer.value = preLoadedOffer
      }

      offerModalShow.value = true
    }

    const submitValidatedOfferLocal = () => {
      submitValidatedOffer(currentOffer.value)
        .then(() => {
          offerModalShow.value = false
          resetOffer()
        })
    }

    const removeOfferLocal = () => {
      removeOffer(currentOffer.value)
        .then(response => {
          offerModalShow.value = false
          resetOffer()
        })
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      currentOffer,
      offerModalShow,
      offerModalTitle,

      // Computed

      // Methods
      newOffer,
      submitValidatedOfferLocal,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    removeOfferAlert (offer = null) {
      if (offer != null) {
        this.currentOffer = offer
      }

      this.$bvModal
        .msgBoxConfirm(this.$t('DeleteAlert', { msg: this.$t('theOffer') }), {
          okVariant: 'danger',
          okTitle: this.capitalize(this.$t('delete')),
          cancelVariant: 'outline-secondary',
          cancelTitle: this.capitalize(this.$t('cancel')),
          centered: true,
        })
        .then(value => {
          if (value == true) {
            this.removeOfferLocal()
          }
        })
    }
  },
  mounted () {
  },
  created () {
  }
}