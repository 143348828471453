<template>
  <b-card
      no-body
      class="overflow-hidden cursor-default p-50"
      @mouseover="isCardHovered=true"
      @mouseleave="isCardHovered=false"
      @click="$emit('click')"
      style="width: 255px;"
  >

    <div class="d-flex justify-content-between mb-50">
      <div class="text-truncate">
        <b>{{ contact._display }}</b>
      </div>

      <Transition name="slide">
        <div v-if="isCardHovered" class="text-nowrap pl-50">
          <icon
              icon="envelope"
              class="mr-1 cursor-pointer"
              :class="{'text-primary': isEnvelopeHovered}"
              :disabled="isEnvelopeDisabled"

              @mouseover="isEnvelopeHovered=true"
              @mouseleave="isEnvelopeHovered=false"
              @click="$emit('enveloppeClick')"
          />
          <icon
              icon="phone"
              class="mr-1 cursor-pointer"
              :class="{'text-primary': isPhoneHovered}"
              :disabled="isPhoneDisabled"

              @mouseover="isPhoneHovered=true"
              @mouseleave="isPhoneHovered=false"
              @click="$emit('phoneClick')"
          />
          <icon
              v-if="!isTaskDisabled"
              icon="calendar-alt"
              class="mr-1 cursor-pointer"
              :class="{'text-primary': isCalendarHovered}"
              :disabled="isCalendarDisabled"

              @mouseover="isCalendarHovered=true"
              @mouseleave="isCalendarHovered=false"
              @click="$emit('calendarClick')"
          />
          <icon
              v-if="!isTaskDisabled"
              icon="tasks"
              class="mr-1 cursor-pointer"
              :class="{'text-primary': isTaskHovered}"
              :disabled="isTaskDisabled"

              @mouseover="isTaskHovered=true"
              @mouseleave="isTaskHovered=false"
              @click="$emit('taskClick')"
          />
        </div>
      </Transition>
    </div>

    <div class="d-flex">
      <div style="width: 12px;" class="mr-50 my-auto">
        <icon icon="envelope" size="sm"/>
      </div>
      <small class="my-auto text-truncate">
        <a v-if="contact.email" :href="'mailto:'+contact.email">{{contact.email}}</a>
        <span v-else>-</span>
      </small>
    </div>

    <div class="d-flex">
      <div style="width: 12px;" class="mr-50 my-auto">
        <icon icon="phone" size="sm"/>
      </div>
      <small class="my-auto text-truncate">
        <a v-if="contact.mobile" :href="'tel:'+contact.mobile">{{contact.mobile}}</a>
        <a v-else-if="contact.phone" :href="'tel:'+contact.phone">{{contact.phone}}</a>
        <span v-else>-</span>
      </small>
    </div>

  </b-card>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

export default {
  components: {},
  props: {
    contact: {
      type: Object,
      default: () => {}
    },
    isEnvelopeDisabled: {
      type: Boolean,
      default: false
    },
    isPhoneDisabled: {
      type: Boolean,
      default: false
    },
    isCalendarDisabled: {
      type: Boolean,
      default: false
    },
    isTaskDisabled: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isCardHovered = ref(false)
    const isEnvelopeHovered = ref(false)
    const isPhoneHovered = ref(false)
    const isCalendarHovered = ref(false)
    const isTaskHovered = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      isCardHovered,
      isEnvelopeHovered,
      isPhoneHovered,
      isCalendarHovered,
      isTaskHovered,

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">
.slide-leave-active,
.slide-enter-active {
  transition: .5s;
}

.slide-enter {
  transform: translateX(100%);
}

.slide-leave-to {
  transform: translateX(200%);
}
</style>