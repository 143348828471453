import { ref, computed, watch }         from '@vue/composition-api'
import { clone, getRoute, getUserData } from '../utils/utils'
import store                            from '../store'
import i18n                     from '../libs/i18n'
import { useReminders }         from '../components/reminder/useReminders'
import { capitalize }           from '../utils/filter'

export const mixinReminders = {
  components: {},
  props: {},
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const currentReminder = ref({})
    const reminderModalShow = ref(false)
    const reminderModalTitle = ref(i18n.t('NewReminder'))

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(reminderModalShow, val => {
      if (val == false) {
        resetReminder()
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { submitValidatedReminder, removeReminder } = useReminders()
    const resetReminder = () => {
      currentReminder.value = clone(store.getters['reminder/getEmptyReminder'])
      reminderModalTitle.value = i18n.t('NewReminder')
    }

    const addReminder = (reminder = null) => {
      if (reminder != null) {
        currentReminder.value = reminder
      }
      reminderModalShow.value = true
    }

    const addExternalReminder = (reminder) => {
      currentReminder.value = clone(reminder)

      reminderModalShow.value = true
    }

    const editExternalReminder = (reminder) => {
      reminderModalTitle.value = capitalize(i18n.t('editReminder'))

      store.dispatch('reminder/getReminder', reminder.id)
        .then(response => {
          currentReminder.value = response.data

          if (
            response.data.isClosed == true &&
            getRoute().name != "WorkFlo view"
          ) {
            removeReminderLocal(response.data.id)
          }

          reminderModalShow.value = true
        })

    }

    const editReminder = (reminder) => {
      reminderModalTitle.value = capitalize(i18n.t('editReminder'))

      store.dispatch('reminder/getReminder', reminder.id)
        .then(response => {
          currentReminder.value = response.data

          if (response.data.isClosed == true) {
            removeReminderLocal(response.data.id)
          }

          reminderModalShow.value = true
        })

    }

    const duplicateReminder = (reminderToDuplicate) => {
      store.dispatch('reminder/getReminder', reminderToDuplicate.id)
        .then(response => {
          currentReminder.value = clone(response.data)
          delete currentReminder.value.id
          delete currentReminder.value.logs

          submitValidatedReminderLocal()
        })
    }

    const checkReminder = (reminder) => {
      store.dispatch('reminder/getReminder', reminder.id)
        .then(response => {
          currentReminder.value = response.data
          currentReminder.value.isClosed = true

          submitValidatedReminderLocal()
        })
    }

    const submitValidatedReminderLocal = () => {
      submitValidatedReminder(currentReminder.value)
        .then(response => {
          // console.log(response)

          reminderModalShow.value = false
          resetReminder()

          emit('updateCalendar')
          emit('updateReminders')
        })
    }

    const removeReminderLocal = (id) => {
      // console.log("01")
      // console.log('remove')

      removeReminder(currentReminder.value)
        .then(response => {
          resetReminder()

          emit('updateCalendar')
          emit('updateReminders')

          reminderModalShow.value = false
        })

    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    resetReminder()

    return {
      // Components

      // Data
      currentReminder,
      reminderModalShow,
      reminderModalTitle,

      // Computed

      // Methods
      addReminder,
      addExternalReminder,
      editExternalReminder,
      editReminder,
      duplicateReminder,
      checkReminder,
      removeReminderLocal,
      submitValidatedReminderLocal,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    removeReminderAlert (reminder = null) {
      console.log("1")
      if (reminder != null) {
        this.currentReminder = reminder
      }

      this.$bvModal
        .msgBoxConfirm(this.$t('DeleteAlert', { msg: this.$t('theReminder') }), {
          okVariant: 'danger',
          okTitle: this.capitalize(this.$t('delete')),
          cancelVariant: 'outline-secondary',
          cancelTitle: this.capitalize(this.$t('cancel')),
          centered: true,
        })
        .then(value => {
          if (value == true) {
            this.removeReminderLocal()
          }
        })
    }
  },
  mounted () {
  },
  created () {
  }
}