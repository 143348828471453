<template>
  <div>
    <b-card>

      <!--      Priority & reminder end-->
      <b-row>
        <b-col md="6">
          <div
              id="popover-reactive-1"
              class="d-flex cursor-pointer"
              style="margin-left: 10px"
          >
            <b-badge
                pill
                :variant="currentBadgeColor"
                class="mr-1"
            ></b-badge>
            <div>
              <b>{{ capitalize($t(reminderLocal.priority)) }}</b>
            </div>
          </div>
          <b-popover
              ref="popover"
              target="popover-reactive-1"
              triggers="click"
              placement="auto"
              container="my-container"
          >
            <div
                class="cursor-pointer py-50 d-flex"

                @click="reminderLocal.priority='high';$root.$emit('bv::hide::popover', 'popover-reactive-1')"
            >
              <b-badge
                  pill
                  variant="danger"
                  class="mr-1"
              ></b-badge>
              <div>
                <b>{{ capitalize($t('high')) }}</b>
              </div>
            </div>
            <div
                class="cursor-pointer py-50 d-flex"

                @click="reminderLocal.priority='medium';$root.$emit('bv::hide::popover', 'popover-reactive-1')"
            >
              <b-badge
                  pill
                  variant="warning"
                  class="mr-1"
              ></b-badge>
              <div>
                <b>{{ capitalize($t('medium')) }}</b>
              </div>
            </div>
            <div
                class="cursor-pointer py-50 d-flex"

                @click="reminderLocal.priority='low';$root.$emit('bv::hide::popover', 'popover-reactive-1')"
            >
              <b-badge
                  pill
                  variant="success"
                  class="badge-glow mr-1"
              ></b-badge>
              <div>
                <b>{{ capitalize($t('low')) }}</b>
              </div>
            </div>

          </b-popover>
        </b-col>

        <b-col md="6" v-if="!isTemplate">
          <div class="d-flex justify-content-between">
            <div class="d-flex">
              <b-form-checkbox
                  v-model="reminderLocal.isClosed"
                  class="custom-control-primary my-auto"
                  switch
              />
              <span class="my-auto">{{ capitalize($t('closedReminder')) }}</span>
            </div>

            <div
                class="my-auto"
                @mouseover="isDeleteIconHovered = true"
                @mouseleave="isDeleteIconHovered = false"
                :class="{'text-danger cursor-pointer': isDeleteIconHovered}"
                @click="$emit('removeReminder')"
            >
              <icon
                  icon="trash-alt"
              />
            </div>
          </div>
        </b-col>
      </b-row>

      <!--      Type + title-->
      <b-row>
        <b-col
            md="12"
            class="d-flex mt-1"
        >
          <b-dropdown
              no-caret
              variant="outline-dark"
              class="align-self-start dropdown-icon mr-1"
              style="width: 40px; height: 37px;"
          >
            <template #button-content>
              <icon
                  :icon="currentReminderIcon.icon"
                  size="2x"
              />
            </template>
            <b-dropdown-item
                v-for="reminderIcon in reminderIcons"
                @click="reminderTypeChange(reminderIcon.text)"
            >
              <div class="d-flex">
                <div style="width: 30px;">
                  <icon
                      :icon="reminderIcon.icon"
                      size="lg"
                  />
                </div>
                <div>{{ capitalize($tc(reminderIcon.text)) }}</div>
              </div>
            </b-dropdown-item>
          </b-dropdown>

          <input-field
              :name="capitalize($t('addTitle'))"
              :display-label="false"
              rules="required"
              :model.sync="reminderLocal.title"
              :with-template="true"
              template-type="reminder"
              class="my-auto w-100"

              @templateSelected="selectTemplate"
          />

        </b-col>
      </b-row>

      <!--      Additional members-->
      <b-row>
        <b-col
            md="6"
            class="d-flex mt-1"
        >
          <div
              class="mr-1 my-auto"
              style="width: 35px"
          >
            <icon
                icon="user-plus"
                size="2x"
            />
          </div>
          <select2
              :name="capitalize($t('inviteParticipants'))"
              :placeholder="capitalize($t('inviteParticipants'))"
              :model.sync="reminderLocal.additionalMembers"
              :options="autocompleteUsers"
              :multiple="true"
              :display-label="false"
              reduce="id"
              label="_display"
              class="my-auto"

              @input="additionalMembersUpdated"
          />
        </b-col>

        <b-col
            md="6"
            class="d-flex mt-1"
        >
          <div
              v-if="['discussion'].includes(reminderLocal.type)"
              class="my-auto"
          >
            <div class="d-flex">
              <b-form-checkbox
                  v-model="reminderLocal.isOnlineMeeting"
                  class="custom-control-primary my-auto"
                  switch
              />
              <span class="my-auto">{{ capitalize($t('onlineMeeting')) }}</span>
            </div>
          </div>
          <div v-else-if="reminderLocal.type == 'call'">
            <switch-field
                name="outgoingCall"
                :model.sync="reminderLocal.isOutgoingCall"
            />
          </div>
        </b-col>
      </b-row>

      <!--      Start date-->
      <b-row v-if="!isTemplate">
        <b-col
            md="6"
            class="d-flex mt-1"
        >

          <div
              class="overflow-hidden mr-1 my-auto cursor-pointer"
              style="flex: none; width: 35px;"
          >
            <icon
                icon="clock"
                size="2x"
            />
          </div>

          <div class="w-100">
            <date-field
                name="startDate"
                rules="required"
                :display-label="false"
                :enableTime="false"
                :model.sync="reminderLocal.completionDate"
                class="w-100"
            />
          </div>

        </b-col>
        <b-col
            md="6"
            class="d-flex mt-1"
        >
        </b-col>
      </b-row>

      <!--      Project-->
      <b-row v-if="!('workFloId' in $router.history.current.params)">
        <b-col
            :md="reminderLocal.workflow == null?12:6"
            class="mt-1"
        >
          <div class="d-flex">
            <div
                class="overflow-hidden mr-1 my-auto"
                style="flex: none; width: 35px;"
            >
              <icon
                  icon="tag"
                  size="2x"
              />
            </div>

            <div
                class="my-auto mr-1"
                style="flex: initial"
            >
              <b-badge
                  v-if="selectedProjectLocal == null"
                  pill
                  variant="light-secondary"
              >{{ capitalize($t('category')) }}
              </b-badge>
              <b-badge
                  v-else-if="projectOptions.find(o=>o.i == selectedProjectLocal).category == 'workFlo'"
                  pill
                  variant="light-primary"
              >{{ capitalize($t('opportunity')) }}
              </b-badge>
              <b-badge
                  v-else-if="projectOptions.find(o=>o.i == selectedProjectLocal).category == 'workflow'"
                  pill
                  variant="light-info"
              >{{ capitalize($t('workflow')) }}
              </b-badge>
            </div>


            <div
                class="my-auto flex-grow-1"
                style="flex: initial"
            >
              <div class="d-flex">
                <select2
                    :model.sync="selectedProjectLocal"
                    :options="projectOptions"
                    :dropdown-parent-size-multiply="1"
                    label="display"
                    reduce="i"
                />

                <icon
                    v-if="selectedProjectLocal != null && reminderLocal.workflow == null"
                    icon="info-circle"
                    class="ml-1 text-info my-auto cursor-pointer"

                    @click="displayProjectPropositions = !displayProjectPropositions"
                />

                <icon
                    v-if="reminderLocal.workFlo != null"
                    icon="external-link-alt"
                    class="ml-50 text-info my-auto cursor-pointer"

                    @click="goToNewTab($router.resolve({name: 'WorkFlo view', params: { workFloId: reminderLocal.workFlo }}).href)"
                />

                <icon
                    v-else-if="reminderLocal.workflow != null"
                    icon="external-link-alt"
                    class="ml-50 text-info my-auto cursor-pointer"

                    @click="goToNewTab($router.resolve({name: 'Workflow view', params: { workflowId: reminderLocal.workflow }}).href)"
                />
              </div>
            </div>

          </div>
        </b-col>

        <b-col
            md="6"
            class="mt-1"
        >
          <div v-if="reminderLocal.workflow != null">
            <select2
                :model.sync="reminderLocal.phase"
                :display-label="false"
                name="phase"
                :options="autocompletePhases"
                label="name"
                :placeholder="$t('Choose a phase')"
            />
          </div>
        </b-col>
      </b-row>
      <b-row v-if="!('workFloId' in $router.history.current.params) && displayProjectPropositions==true && reminderLocal.additionalMembers.length != 0">
        <b-col
            md="12"
            class="mt-1"
        >
          <div class="d-flex h-100">
            <div
                v-for="projectProposition in projectPropositions"
                class="text-truncate mx-50 my-auto cursor-pointer"
                style="flex: 1 1 0;"

                @click="selectedProjectLocal = projectProposition.i"
            >
              <b-badge
                  v-if="projectProposition.category == 'workFlo'"
                  variant="light-primary"
                  class="text-truncate fill"
                  v-b-tooltip.hover.top="projectProposition.display"
              >
                {{ projectProposition.display }}
              </b-badge>
              <b-badge
                  v-else-if="projectProposition.category == 'workflow'"
                  variant="light-info"
                  class="text-truncate fill"
                  v-b-tooltip.hover.top="projectProposition.display"
              >
                {{ projectProposition.display }}
              </b-badge>
            </div>
          </div>
        </b-col>
      </b-row>

      <!--      Place-->
      <b-row
          v-if="reminderLocal.type == 'intervention'"
      >
        <b-col
            md="6"
            class="d-flex mt-1"
        >
          <div
              class="overflow-hidden mr-1 my-auto"
              style="flex: none; width: 35px;"
          >
            <icon
                icon="map-marker-alt"
                size="2x"
            />
          </div>

          <div class="my-auto w-100">
            <field-address-by-query
                name="place"
                :display-label="false"
                :model.sync="reminderLocal.place"
                :displayComplement="false"
            />
          </div>
        </b-col>

        <b-col
            md="6"
            class="mt-1"
        >
          <div class="d-flex h-100">
            <div
                v-for="addressProposition in addressPropositions"
                class="text-truncate mx-50 my-auto cursor-pointer"
                style="flex: 1 1 0;"

                @click="reminderLocal.place = addressProposition._display"
            >
              <b-badge
                  variant="light-secondary"
                  class="text-truncate fill"
                  v-b-tooltip.hover.top="addressProposition._display"
              >
                {{ addressProposition._display }}
              </b-badge>
            </div>
          </div>

        </b-col>
      </b-row>

      <!--      Trip-->
      <b-row
          v-if="reminderLocal.type == 'trip'"
      >
        <b-col
            md="12"
            class="d-flex mt-1"
        >
          <div
              class="overflow-hidden mr-1 my-auto"
              style="flex: none; width: 35px;"
          >
            <icon
                icon="map-marker-alt"
                size="2x"
            />
          </div>

          <div class="my-auto w-100">
            <field-address-by-query
                :name="capitalize($t('startPlace'))"
                :model.sync="reminderLocal.startPlace"
                :display-label="false"
                :isRequired="true"
                :displayComplement="false"
                ref="startPlaceRef"
                class="w-100"
            />
          </div>

          <icon
              icon="exchange-alt"
              class="mt-50 mx-2 cursor-pointer"
              @click.native="intervertAddresses"
          />

          <div class="my-auto w-100">
            <field-address-by-query
                :name="capitalize($t('endPlace'))"
                :model.sync="reminderLocal.endPlace"
                :display-label="false"
                :isRequired="true"
                :displayComplement="false"
                ref="endPlace"
                class="w-100"
            />
          </div>


        </b-col>

        <b-col md="12">
          <div class="d-flex justify-content-between h-100">
            <div
                v-for="addressProposition in addressTripPropositions"
                class="text-truncate mx-50 py-50 my-auto cursor-pointer"
                style=""

                @click="selectTripAddresses(addressProposition)"
            >
              <b-badge
                  variant="light-secondary"
                  class="text-truncate fill"
                  v-b-tooltip.hover.top="addressProposition.startPlace._display + ' 🠮 ' + addressProposition.endPlace._display"
              >
                {{ addressProposition.startPlace._display }} 🠮 {{ addressProposition.endPlace._display }}
              </b-badge>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="reminderLocal.type == 'trip'">
        <b-col
            md="12"
            class="d-flex mt-1"
        >
          <b-form-checkbox
              v-model="reminderLocal.isTripWithPersonalVehicle"
              class="custom-control-primary form-group"
              switch
          >
          </b-form-checkbox>
          <span class="my-auto">{{ capitalize($t('tripWithPersonalVehicle')) }}</span>
        </b-col>
      </b-row>

      <!--      Discussion Platform-->
      <b-row
          v-if="reminderLocal.type == 'discussion' && reminderLocal.isOnlineMeeting == false"
      >
        <b-col
            md="6"
            class="d-flex mt-1"
        >
          <div
              class="overflow-hidden mr-1 my-auto"
              style="flex: none; width: 35px;"
          >
            <icon
                icon="comment-dots"
                size="2x"
            />
          </div>

          <select2
              :model.sync="reminderLocal.discussionPlatform"
              :options="discussionOptions"
              :clearable="false"
              class=""
          />
        </b-col>

      </b-row>

      <!--      Tabs-->
      <event2-tabs
          :item="reminderLocal"
          class="mt-2"
      />

    </b-card>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize }           from '../../../utils/filter'
import InputField                                   from '../../input/Input.vue'
import { clone, getUserData, goToNewTab, isObject } from '../../../utils/utils'
import SwitchField                                  from '../../input/Switch.vue'
import Select2                  from '../../input/Select2.vue'
import useAPI                   from '../../../utils/useAPI'
import store                    from '../../../store'
import DateField                from '../../input/Date.vue'
import FieldAddressByQuery      from '../../input/AddressAutosuggest.vue'
import Event2Tabs               from '../event2/components/Event2Tabs.vue'

const discussionOptions = [
  'SMS', 'Présentiel', 'WhatsApp', 'LinkedIn', 'Instagram', 'X', 'Facebook'
]
export default {
  components: { Event2Tabs, FieldAddressByQuery, DateField, Select2, SwitchField, InputField },
  props: {
    reminder: {
      type: Object,
      default: () => {}
    },
    isTemplate: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // console.log(clone(props.reminder))
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const reminderIcons = ref([
      {
        icon: 'user-circle',
        text: 'work'
      },
      {
        icon: 'comments',
        text: 'discussion'
      },
      {
        icon: 'phone',
        text: 'call'
      },
      {
        icon: 'envelope',
        text: 'mail'
      },
      {
        icon: 'briefcase',
        text: 'intervention'
      },
      {
        icon: 'suitcase-rolling',
        text: 'trip'
      },
    ])
    const selectedProjectLocal = ref()
    const projectPropositions = ref([])
    const addressPropositions = ref([])
    const addressTripPropositions = ref([])
    const displayProjectPropositions = ref(true)
    const isDeleteIconHovered = ref(false)
    const usersInput = ref()

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const {
      autocompleteUsers,
      autocompleteWorkFlos,
      autocompleteWorkflows,
      autocompletePhases
    } = useAPI()

    const reminderLocal = computed({
      get () {
        return props.reminder
      },
      set (val) {
        emit('update:reminder', val)
      }
    })

    const currentBadgeColor = computed(() => {
      if (props.reminder.priority == 'high') {
        return 'danger'
      } else if (props.reminder.priority == 'medium') {
        return 'warning'
      } else {
        return 'success'
      }
    })

    const currentReminderIcon = computed(() => {
      if (!('type' in reminderLocal.value) && reminderLocal.value.type == null) {
        return reminderIcons.value[0]
      } else {
        return reminderIcons.value.filter(ri => ri.text == reminderLocal.value.type)[0]
      }
    })

    const projectOptions = computed(() => {
      let output = []

      let index = 0
      autocompleteWorkFlos.value.forEach(w => {
        output.push({
          i: ++index,
          category: 'workFlo',
          workFloId: w.id,
          workflowId: null,
          display: w._display
        })
      })
      autocompleteWorkflows.value.forEach(wfw => {
        output.push({
          i: ++index,
          category: 'workflow',
          workFloId: null,
          workflowId: wfw.id,
          display: wfw._display
        })
      })

      // console.log(output)

      return output.sort((a, b) => a.display < b.display ? -1 : 1)
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(selectedProjectLocal, val => {
      if (val == null) {
        reminderLocal.value.workFlo = null
        reminderLocal.value.workflow = null
        reminderLocal.value.phase = null

        displayProjectPropositions.value = true
      } else {
        displayProjectPropositions.value = false

        let concernedProjectOption = projectOptions.value.find(x => x.i == val)

        if (concernedProjectOption.category == 'workFlo') {
          reminderLocal.value.workFlo = concernedProjectOption.workFloId
          reminderLocal.value.workflow = null
          reminderLocal.value.phase = null
        } else if (concernedProjectOption.category == 'workflow') {
          reminderLocal.value.workFlo = null

          if (reminderLocal.value.workflow == null || concernedProjectOption.workflowId != reminderLocal.value.workflow.id) {
            reminderLocal.value.workflow = concernedProjectOption.workflowId
            reminderLocal.value.phase = null
          }

          updatePhases(concernedProjectOption.workflowId)
        }
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { fetchAutocompletePhases } = useAPI()

    const reminderTypeChange = (newType) => {
      // console.log('start', moment(clone(reminderLocal.value.startDate)).format('LLL'))
      // console.log('end', moment(clone(reminderLocal.value.endDate)).format('LLL'))

      reminderLocal.value.type = newType
      reminderLocal.value.visioLink = null
      reminderLocal.value.discussionPlatform = null
      reminderLocal.value.place = null

      if (newType == 'discussion') {
        reminderLocal.value.discussionPlatform = 'Présentiel'
      } else if (newType == 'trip') {
        if (getUserData().companyMainAddress != null) {
          setTimeout(() => {
            reminderLocal.value.startPlace = getUserData().companyMainAddress
          }, 100)
        }
        reminderLocal.value.discussionPlatform = 'SMS'
      }
    }

    const additionalMembersUpdated = additionalMembers => {
      selectedProjectLocal.value = null

      getUserLastAnnexations(additionalMembers)
      getUserLastPlaces(additionalMembers)
      getUserLastTrips(additionalMembers)
    }

    const getUserLastAnnexations = additionalMembers => {
      projectPropositions.value = []

      if (additionalMembers.length) {
        store.dispatch('user2/getUserLastAnnexations', additionalMembers[0])
            .then(response => {
              let wf = 0
              response.data.workFlos.forEach(w => {
                if (wf < 3) {
                  projectPropositions.value.push({
                    i: projectOptions.value.find(o => o.category == 'workFlo' && o.workFloId == w.id).i,
                    category: 'workFlo',
                    workFloId: w.id,
                    display: w._display
                  })
                }
                wf++
              })

              let wfw = 0
              response.data.workflows.forEach(wf => {
                if (wfw < 1) {
                  projectPropositions.value.push({
                    i: projectOptions.value.find(o => o.category == 'workflow' && o.workflowId == wf.id).i,
                    category: 'workflow',
                    workflowId: wf.id,
                    display: wf._display
                  })
                }
                wfw++
              })

            })
      }

    }

    const getUserLastPlaces = additionalMembers => {
      addressPropositions.value = []

      if (additionalMembers.length) {
        store.dispatch('user2/getUserLastPlaces', additionalMembers[0])
            .then(response => {
              for (let i = 0; i < 3; i++) {
                if (response.data[i] !== undefined) {
                  addressPropositions.value.push(response.data[i])
                }
              }
            })
      }
    }

    const getUserLastTrips = additionalMembers => {
      addressTripPropositions.value = []

      if (additionalMembers.length) {
        store.dispatch('user2/getUserLastTrips', additionalMembers[0])
            .then(response => {
              for (let i = 0; i < 3; i++) {
                if (response.data[i] !== undefined) {
                  addressTripPropositions.value.push(response.data[i])
                }
              }
            })
      }
    }

    const updatePhases = (worflowId) => {
      if (worflowId != null) {
        fetchAutocompletePhases({
          filters: [{
            sectionLogicOperator: 'AND',
            sectionFilters: [
              {
                logicOperator: 'AND',
                operator: 'equal',
                field: 'workflow',
                value: worflowId
              }
            ]
          }]
        })
      }
    }

    const intervertAddresses = () => {
      if (
          reminderLocal.value.startPlace != null &&
          reminderLocal.value.startPlace != '' &&
          reminderLocal.value.endPlace != null &&
          reminderLocal.value.endPlace != ''
      ) {
        let startPlace = clone(reminderLocal.value.startPlace)
        let endPlace = clone(reminderLocal.value.endPlace)
        reminderLocal.value.startPlace = endPlace
        reminderLocal.value.endPlace = startPlace
      }
    }

    const selectTripAddresses = addressProposition => {
      reminderLocal.value.startPlace = addressProposition.startPlace._display
      reminderLocal.value.endPlace = addressProposition.endPlace._display
    }
    const selectTemplate = template => {
      store.dispatch('template/getTemplate', template.id)
          .then(response => {
            for (const [key, value] of Object.entries(response.data.template)) {
              reminderLocal.value[key] = value
            }

            let additionalMembers = []
            if (response.data.template.additionalMembers.length != 0) {
              response.data.template.additionalMembers.forEach(additionalMember => {
                additionalMembers.push(autocompleteUsers.value.find(u=>u.id==additionalMember))
              })

              reminderLocal.value.additionalMembers = additionalMembers
            }

            usersInput.value.update(additionalMembers)
          })
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    if (props.reminder.workflow != null) {
      let workflowId = isObject(props.reminder.workflow)?props.reminder.workflow.id:props.reminder.workflow
      selectedProjectLocal.value = projectOptions.value.find(p => p.workflowId != null && p.workflowId == workflowId).i
    }
    if (props.reminder.workFlo != null) {
      let workFloId = isObject(props.reminder.workFlo)?props.reminder.workFlo.id:props.reminder.workFlo
      selectedProjectLocal.value = projectOptions.value.find(p => p.workFloId != null && p.workFloId == workFloId).i
    }
    if (props.reminder.additionalMembers.length) {
      let additionalMembersIds = []
      props.reminder.additionalMembers.forEach(m => additionalMembersIds.push(m.id))

      getUserLastAnnexations(additionalMembersIds)
    }

    if (props.isTemplate) {
      let additionalMembers = []
      if (props.reminder.additionalMembers.length != 0) {
        props.reminder.additionalMembers.forEach(additionalMember => {
          additionalMembers.push(autocompleteUsers.value.find(u=>u.id==additionalMember))
        })

        reminderLocal.value.additionalMembers = additionalMembers
      }
    }

    return {
      // Components

      // Data
      reminderIcons,
      selectedProjectLocal,
      projectPropositions,
      addressPropositions,
      addressTripPropositions,
      displayProjectPropositions,
      discussionOptions,
      isDeleteIconHovered,
      usersInput,

      // Computed
      autocompleteUsers,
      autocompletePhases,
      reminderLocal,
      currentBadgeColor,
      currentReminderIcon,
      projectOptions,

      // Methods
      reminderTypeChange,
      additionalMembersUpdated,
      intervertAddresses,
      selectTripAddresses,
      selectTemplate,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { goToNewTab, capitalize },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>
.badge:empty {
  display: block;
  width: 20px;
  height: 20px;
}
</style>