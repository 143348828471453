import store from '@/store'

export const useWorkFlos = () => {
  // ======================================================================
  // ==================              METHODS             ==================
  // ======================================================================
  const submitValidatedWorkFlo = (workFlo) => {
    return new Promise((resolve, reject) => {
      if ('id' in workFlo) {
        updateWorkFlo(workFlo)
          .then(response => {
            console.log(response)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      } else {
        saveNewWorkFlo(workFlo)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      }
    })
  }

  const saveNewWorkFlo = (workFlo) => {
    return new Promise((resolve, reject) => {
      store.dispatch('workFlo/addWorkFlo', workFlo)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const updateWorkFlo = (workFlo) => {
    return new Promise((resolve, reject) => {
      store.dispatch('workFlo/updateWorkFlo', workFlo)
        .then(response => {
          // console.log(response)
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const removeWorkFlo = (workFlo) => {
    return new Promise((resolve, reject) => {
      store.dispatch('workFlo/removeWorkFlo', workFlo)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  // ======================================================================
  // ==================               SETUP              ==================
  // ======================================================================
  return {
    submitValidatedWorkFlo,
    removeWorkFlo
  }
}