<template>
  <b-card
      no-body
      v-b-hover="handleHover"
      class="p-50 cursor-pointer"
      @click="$emit('click')"
      @click.middle="$emit('middleClick')"
  >

    <!--    Line 1-->
    <div
        class="w-100 d-flex justify-content-between"
        style="min-height: 24px;"
    >
      <div class="d-flex text-truncate my-auto">
        <div
            v-if="isSelectable"
            class="mr-50 my-auto"
        >
          <b-form-checkbox
              @input="$emit('check', $event)"
          />
        </div>

        <div class="text-truncate my-auto font-weight-bold text-primary">
          {{ workFlo.company._display }}
        </div>
      </div>


      <Transition name="fade">
        <div
            v-if="isHovered"
            class="my-auto d-flex pl-50"
        >
          <edit-button
              @click.native.stop="$emit('edit')"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              :disabled="!isEditable"
              size="sm"
          />

          <view-button
              @click.native.stop="$emit('view')"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          <expand-button-->
          <!--              @click.native.stop="$emit('expand')"-->
          <!--              :withIcon="true"-->
          <!--              :withText="false"-->
          <!--              :withBorder="false"-->
          <!--              size="sm"-->
          <!--          />-->

          <duplicate-button
              @click.native.stop="$emit('duplicate')"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <remove-button
              @click.native.stop="$emit('remove')"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              :is-disabled="!isDeletable"
              size="sm"
          />

        </div>
      </Transition>
    </div>

    <!--    Line 2-->
    <div class="my-50">
      <div class="text-truncate ">
        {{ workFlo.title }}
      </div>
      <div class="w-100 d-flex line-height-1 justify-content-between">
        <div
            v-b-tooltip.hover.right="$t('Number of days since prospect date')"
            class="text-muted"
        >
          <small>{{ workFlo._daysSinceProspectDate }} {{ $tc('day', workFlo._daysSinceProspectDate) }}</small>
        </div>
        <div
            v-b-tooltip.hover.left="$t('Total time spent on the opportunity')"
            class="ml-50 text-muted"
        >
          <small>({{ workFlo._hoursSpent }}h)</small>
        </div>
      </div>
    </div>

    <!--    Line 3 - Main contact-->
    <div
        ref="mainContactRef"
        v-b-hover="handleMainContactHover"
        class="d-flex justify-content-between mt-50"
        style="min-height: 24px;"
    >
      <div
          class="d-flex my-auto text-truncate"
      >
        <b-avatar
            v-if="workFlo.mainContact != null && workFlo.mainContact.avatar"
            class="pull-up"
            :src="workFlo.mainContact.avatar.url"
            variant="light-primary"
            size="sm"
        />
        <b-avatar
            v-else
            class="pull-up"
            :text="workFlo.mainContact!=null?initials(workFlo.mainContact._display):'?'"
            variant="light-primary"
            size="sm"
        />

        <div class="ml-50 text-truncate">
          {{ workFlo.mainContact != null ? workFlo.mainContact._display : '?' }}
        </div>
      </div>

      <Transition name="fade">
        <div
            v-if="isMainContactHovered"
            class="my-auto d-flex pl-50"
        >
          <add-button
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
              @click.native.stop.prevent="showMainContactPopover=!showMainContactPopover"
          />
        </div>
      </Transition>
    </div>

    <!--    Line 4 - Last activity -->
    <div
        ref="lastActivityRef"
        v-b-hover="handleLastActivityHover"
        class="d-flex justify-content-between mt-50"
        style="min-height: 24px;"
    >
      <div
          class="d-flex my-auto text-truncate"
      >
        <b-badge
            :variant="displayLastActivity.color"
            style="padding: 0.4rem 0.5rem;"
        >
          <icon
              :icon="displayLastActivity.icon.icon"
              class="mr-50"
          />
          {{ displayLastActivity.text }}
        </b-badge>
      </div>

      <Transition name="fade">
        <div
            v-if="isLastActivityHovered"
            class="my-auto d-flex pl-50"
        >
          <add-button
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
              @click.native.stop.prevent="showLastActivityPopover=!showLastActivityPopover"
          />
        </div>
      </Transition>
    </div>

    <!--    Line 5 - Affected user -->
    <div
        v-b-hover="workFlo.assignment != null?workFlo.assignment._display:'?'"
        class="d-flex justify-content-between mt-50"
        style="min-height: 24px;"
    >
      <div
          class="d-flex my-auto text-truncate"
      >
        <b-avatar
            v-if="workFlo.assignment != null && workFlo.assignment.avatar"
            class="pull-up2"
            :src="workFlo.assignment.avatar.url"
            variant="light-primary"
            size="sm"
        />
        <b-avatar
            v-else
            class="pull-up2"
            :text="workFlo.assignment!=null?initials(workFlo.assignment._display):'?'"
            variant="light-primary"
            size="sm"
        />

        <div class="ml-50 text-truncate">
          {{ workFlo.assignment != null ? workFlo.assignment._display : '?' }}
        </div>
      </div>
    </div>

    <!--    Main contact popover-->
    <b-popover
        v-if="isMainContactReady"
        :show.sync="showMainContactPopover"
        ref="popover"
        :target="$refs.mainContactRef"
        triggers="manual"
        placement="auto"
        container="my-container"
        custom-class="workFloCardPopover"
    >
      <div @click="showMainContactPopover=false">

        <!--        Call-->
        <div
            class="d-flex p-50 rounded-sm"
            :class="{'bg-primary bg-lighten-5': isMainContactCallHovered && mainContactPhone!=null, 'text-muted': mainContactPhone==null, 'cursor-pointer': mainContactPhone!=null}"
            v-b-hover="handleMainContactCallHover"

            @click="callMainContact"
        >
          <icon
              icon="phone"
              :disabled="mainContactPhone==null"
          />
          <div class="ml-1 disabled">{{ $t('CallContact') }}</div>
        </div>

        <!--        Mail-->
        <div
            class="d-flex p-50 rounded-sm"
            :class="{'bg-primary bg-lighten-5': isMainContactMailHovered && mainContactMail!=null, 'text-muted': mainContactMail==null, 'cursor-pointer': mainContactMail!=null}"
            v-b-hover="handleMainContactMailHover"

            @click="mailMainContact"
        >
          <icon
              icon="envelope"
              :disabled="mainContactMail==null"
          />
          <div class="ml-1 disabled">{{ $t('NewEmail') }}</div>
        </div>

        <!--        Exchanges -->
        <!--        <div-->
        <!--            class="d-flex p-50 rounded-sm"-->
        <!--            :class="{'bg-primary bg-lighten-5': isMainContactExchangeHovered, 'text-muted': mainContactExchange==null, 'cursor-pointer': mainContactExchange!=null}"-->
        <!--            v-b-hover="handleMainContactExchangeHover"-->

        <!--            @click="mailMainContact"-->
        <!--        >-->
        <!--          <icon-->
        <!--              icon="comments"-->
        <!--              :disabled="mainContactExchange==null"-->
        <!--          />-->
        <!--          <div class="ml-1 disabled">{{ $tc('Exchange', 2) }}</div>-->
        <!--        </div>-->

        <!--        Incoming call -->
        <!--        <div-->
        <!--            class="d-flex p-50 rounded-sm text-muted"-->
        <!--        >-->
        <!--          <icon-->
        <!--              icon="phone-volume"-->
        <!--              :disabled="true"-->
        <!--          />-->
        <!--          <div class="ml-1">Appel du contact</div>-->
        <!--        </div>-->

      </div>
    </b-popover>

    <!--    Last activity popover-->
    <b-popover
        v-if="isLastActivityReady"
        :show.sync="showLastActivityPopover"
        ref="popover"
        :target="$refs.lastActivityRef"
        triggers="manual"
        placement="auto"
        container="my-container"
        custom-class="workFloCardPopover"
    >
      <div @click="showLastActivityPopover=false">

        <!--        Work time-->
        <div
            class="d-flex p-50 rounded-sm cursor-pointer"
            :class="{'bg-primary bg-lighten-5': isLastActivityWorkTimeHovered}"
            v-b-hover="handleLastActivityWorkTimeHover"

            @click="addNewEvent('work')"
        >
          <div
              class="d-flex"
              style="width: 16px;"
          >
            <icon
                class="mx-auto"
                icon="user"
            />
          </div>
          <div class="ml-1">{{ $t('NewWorkTime') }}</div>
        </div>

        <!--        Exchange-->
        <div
            class="d-flex p-50 rounded-sm cursor-pointer"
            :class="{'bg-primary bg-lighten-5': isLastActivityExchangeHovered}"
            v-b-hover="handleLastActivityExchangeHover"

            @click="addNewEvent('discussion')"
        >
          <div
              class="d-flex"
              style="width: 16px;"
          >
            <icon
                class="mx-auto"
                icon="comments"
            />
          </div>
          <div class="ml-1">{{ $t('NewExchange') }}</div>
        </div>

<!--        &lt;!&ndash;        NewEmail&ndash;&gt;-->
<!--        <div-->
<!--            class="d-flex p-50 rounded-sm text-muted"-->
<!--        >-->
<!--          <icon-->
<!--              icon="envelope"-->
<!--          />-->
<!--          <div class="ml-1">{{ $t('NewEmail') }}</div>-->
<!--        </div>-->

        <!--        Reminder-->
        <div
            class="d-flex p-50 rounded-sm cursor-pointer"
            :class="{'bg-primary bg-lighten-5': isLastActivityReminderHovered}"
            v-b-hover="handleLastActivityReminderHover"

            @click="addNewReminder"
        >
          <div
              class="d-flex"
              style="width: 16px;"
          >
            <icon
                class="mx-auto"
                icon="history"
            />
          </div>
          <div class="ml-1">{{ $t('NewReminder') }}</div>
        </div>

        <!--        Offer -->
        <div
            class="d-flex p-50 rounded-sm cursor-pointer"
            :class="{'bg-primary bg-lighten-5': isLastActivityOfferHovered}"
            v-b-hover="handleLastActivityOfferHover"

            @click="addNewOffer"
        >
          <div
              class="d-flex"
              style="width: 16px;"
          >
            <icon
                class="mx-auto"
                icon="file-alt"
            />
          </div>
          <div class="ml-1">{{ $t('NewOffer') }}</div>
        </div>

        <!--        Order -->
        <!--        <div-->
        <!--            class="d-flex p-50 rounded-sm cursor-pointer"-->
        <!--            :class="{'bg-primary bg-lighten-5': isLastActivityOrderHovered}"-->
        <!--            v-b-hover="handleLastActivityOrderHover"-->

        <!--            @click="$emit('newOrder')"-->
        <!--        >-->
        <!--          <div-->
        <!--              class="d-flex"-->
        <!--              style="width: 16px;"-->
        <!--          >-->
        <!--            <icon-->
        <!--                class="mx-auto"-->
        <!--                icon="file-signature"-->
        <!--            />-->
        <!--          </div>-->
        <!--          <div class="ml-1">{{ $t('NewOrder') }}</div>-->
        <!--        </div>-->

        <!--        Invoice -->
        <!--        <div-->
        <!--            class="d-flex p-50 rounded-sm cursor-pointer"-->
        <!--            :class="{'bg-primary bg-lighten-5': isLastActivityInvoiceHovered}"-->
        <!--            v-b-hover="handleLastActivityInvoiceHover"-->

        <!--            @click="$emit('transformToInvoice')"-->
        <!--        >-->
        <!--          <div-->
        <!--              class="d-flex"-->
        <!--              style="width: 16px;"-->
        <!--          >-->
        <!--            <icon-->
        <!--                class="mx-auto"-->
        <!--                icon="file-invoice-dollar"-->
        <!--            />-->
        <!--          </div>-->
        <!--          <div class="ml-1">{{ $t('TransformAInvoice') }}</div>-->
        <!--        </div>-->

        <!--        Intervention -->
        <div
            class="d-flex p-50 rounded-sm cursor-pointer"
            :class="{'bg-primary bg-lighten-5': isLastActivityInterventionHovered}"
            v-b-hover="handleLastActivityInterventionHover"

            @click="addNewEvent('intervention')"
        >
          <div
              class="d-flex"
              style="width: 16px;"
          >
            <icon
                class="mx-auto"
                icon="briefcase"
            />
          </div>
          <div class="ml-1">{{ $t('NewIntervention') }}</div>
        </div>

        <!--        Trip -->
        <div
            class="d-flex p-50 rounded-sm cursor-pointer"
            :class="{'bg-primary bg-lighten-5': isLastActivityTripHovered}"
            v-b-hover="handleLastActivityTripHover"

            @click="addNewEvent('trip')"
        >
          <div
              class="d-flex"
              style="width: 16px;"
          >
            <icon
                class="mx-auto"
                icon="suitcase-rolling"
            />
          </div>
          <div class="ml-1">{{ $t('NewTrip') }}</div>
        </div>

      </div>
    </b-popover>
  </b-card>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import EditButton               from '../button/Edit.vue'
import DuplicateButton          from '../button/Duplicate.vue'
import RemoveButton             from '../button/Remove.vue'
import AddButton                from '../button/Add.vue'
import ViewButton                       from '../button/View.vue'
import { clone, getUserData, initials } from '../../utils/utils'
import { capitalize }                   from '../../utils/filter'
import i18n                     from '../../libs/i18n'
import moment                   from 'moment'
import store                    from '../../store'
import ExpandButton             from '../button/ExpandButton.vue'

export default {
  components: { ExpandButton, EditButton, DuplicateButton, RemoveButton, AddButton, ViewButton },
  props: {
    workFlo: {
      type: Object,
      default: () => {}
    },
    isSelectable: {
      type: Boolean,
      default: false
    },
    isEditable: {
      type: Boolean,
      default: true
    },
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isHovered = ref(false)
    const isMainContactHovered = ref(false)
    const showMainContactPopover = ref(false)
    const mainContactRef = ref()
    const isMainContactCallHovered = ref(false)
    const isMainContactMailHovered = ref(false)
    const isMainContactExchangeHovered = ref(false)
    const isLastActivityHovered = ref(false)
    const showLastActivityPopover = ref(false)
    const lastActivityRef = ref()
    const isLastActivityWorkTimeHovered = ref(false)
    const isLastActivityExchangeHovered = ref(false)
    const isLastActivityOfferHovered = ref(false)
    const isLastActivityOrderHovered = ref(false)
    const isLastActivityInvoiceHovered = ref(false)
    const isLastActivityInterventionHovered = ref(false)
    const isLastActivityTripHovered = ref(false)
    const isLastActivityReminderHovered = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const isMainContactReady = computed(() => {
      return mainContactRef.value != null
    })

    const mainContactPhone = computed(() => {
      if (props.workFlo.mainContact != null && props.workFlo.mainContact.mobile != null) {
        return props.workFlo.mainContact.mobile
      } else if (props.workFlo.mainContact != null && props.workFlo.mainContact.phone != null) {
        return props.workFlo.mainContact.phone
      } else {
        return null
      }
    })

    const mainContactMail = computed(() => {
      if (props.workFlo.mainContact != null && props.workFlo.mainContact.email != null) {
        return props.workFlo.mainContact.email
      } else {
        return null
      }
    })

    const mainContactExchange = computed(() => {
      return null
    })

    const displayLastActivity = computed(() => {
      if (
          '_lastActivity' in props.workFlo &&
          props.workFlo._lastActivity.type != null
      ) {
        let color = 'secondary'
        let now = moment()

        // if (props.workFlo._lastActivity.entity.type != null) {
        let type = capitalize(i18n.tc('event'))

        if (
            props.workFlo._lastActivity.entity.type == 'call' ||
            props.workFlo._lastActivity.entity.type == 'discussion' ||
            props.workFlo._lastActivity.entity.type == 'mail'
        ) {
          type = capitalize(i18n.t('exchange'))
        } else if (props.workFlo._lastActivity.entity.type == 'intervention') {
          type = capitalize(i18n.tc('intervention'))
        } else if (props.workFlo._lastActivity.entity.type == 'trip') {
          type = capitalize(i18n.tc('trip'))
        }

        if (props.workFlo._lastActivity.type == 'event') {
          let date = moment(props.workFlo._lastActivity.entity.startDate)

          if (
              now.format('YYYY-MM-DD') != date.format('YYYY-MM-DD') &&
              date < now
          ) {
            color = 'danger'
          }

          return {
            icon: getIcon(props.workFlo._lastActivity.entity.type),
            color: color,
            text: i18n.t('Xscheduled', { type: type }) + ' ' + displayDate(props.workFlo._lastActivity.entity.startDate)
          }
        } else if (props.workFlo._lastActivity.type == 'reminder') {
          let date = moment(props.workFlo._lastActivity.entity.completionDate)

          if (
              now.format('YYYY-MM-DD') != date.format('YYYY-MM-DD') &&
              date < now
          ) {
            color = 'danger'
          }

          if (type == capitalize(i18n.tc('event'))) {
            type = capitalize(i18n.tc('reminder'))
          }

          return {
            icon: getIcon(props.workFlo._lastActivity.entity.type),
            color: color,
            text: i18n.t('Xscheduled', { type: type }) + ' ' + displayDate(props.workFlo._lastActivity.entity.completionDate)
          }
        } else if (props.workFlo._lastActivity.type == 'email') {
          let date = moment(props.workFlo._lastActivity.entity.receivedDateTime)

          if (
              now.format('YYYY-MM-DD') != date.format('YYYY-MM-DD') &&
              date < now
          ) {
            color = 'danger'
          }

          return {
            icon: getIcon(props.workFlo._lastActivity.type),
            color: color,
            text: capitalize(i18n.t('emailSent')) + ' ' + displayDate(date)
          }
        } else if (props.workFlo._lastActivity.type == 'offer') {
          let date = moment(props.workFlo._lastActivity.entity.billNumber==null?props.workFlo._lastActivity.entity.createdAt:props.workFlo._lastActivity.entity.billingDate)

          if (
              now.format('YYYY-MM-DD') != date.format('YYYY-MM-DD') &&
              date < now
          ) {
            color = 'danger'
          }

          return {
            icon: getIcon(props.workFlo._lastActivity.type),
            color: color,
            text: capitalize(i18n.tc('offer')) + ' ' + displayDate(date)
          }
        } else if (props.workFlo._lastActivity.type == 'customerInvoice') {
          let date = moment(props.workFlo._lastActivity.entity.billingDate)

          if (
              now.format('YYYY-MM-DD') != date.format('YYYY-MM-DD') &&
              date < now
          ) {
            color = 'danger'
          }

          return {
            icon: getIcon(props.workFlo._lastActivity.type),
            color: color,
            text: capitalize(i18n.tc('customerInvoice')) + ' ' + displayDate(date)
          }
        }
      } else {
        return {
          icon: {
            icon: 'exclamation-triangle',
            library: ''
          },
          color: 'danger',
          text: capitalize(i18n.t('noActionPlanned'))
        }
      }
    })

    const isLastActivityReady = computed(() => {
      return lastActivityRef.value != null
    })

    const isDeletable = computed(() => {
      return true
      // return props.workFlo.assignment.id == getUserData().id
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const handleHover = (hovered) => {
      isHovered.value = hovered
      // isHovered.value = true
    }

    const handleMainContactHover = (hovered) => {
      if (hovered == true) {
        showLastActivityPopover.value = false
      }
      isMainContactHovered.value = hovered
      // isMainContactHovered.value = true
    }
    const handleMainContactCallHover = (hovered) => {
      isMainContactCallHovered.value = hovered
      // isMainContactHovered.value = true
    }
    const handleMainContactMailHover = (hovered) => {
      isMainContactMailHovered.value = hovered
    }
    const handleMainContactExchangeHover = (hovered) => {
      isMainContactExchangeHovered.value = hovered
    }
    const handleLastActivityHover = (hovered) => {
      if (hovered == true) {
        showMainContactPopover.value = false
      }
      isLastActivityHovered.value = hovered
      // isMainContactHovered.value = true
    }

    const callMainContact = () => {
      if (mainContactPhone.value != null) {
        // console.log('callMainContact', mainContactPhone.value)
        window.location.href = 'tel:' + mainContactPhone.value
      }
    }

    const mailMainContact = () => {
      if (mainContactMail.value != null) {
        if (getUserData().roles.includes('ROLE_ADMIN')) {
          emit('mailMainContact', props.workFlo.mainContact)
        } else {
          window.location.href = 'mailto:' + mainContactMail.value
        }
      }
    }

    const exchangeMainContact = () => {
      if (mainContactExchange.value != null) {
        // console.log('exchangeMainContact', mainContactExchange.value)
      }
    }

    const getIcon = type => {
      if (type == 'work') {
        return {
          icon: 'user-circle',
          library: 'fas'
        }
      } else if (type == 'discussion') {
        return {
          icon: 'comments',
          library: 'fas'
        }
      } else if (type == 'call') {
        return {
          icon: 'phone',
          library: 'fas'
        }
      } else if (type == 'mail') {
        return {
          icon: 'envelope',
          library: 'fas'
        }
      } else if (type == 'intervention') {
        return {
          icon: 'briefcase',
          library: 'fas'
        }
      } else if (type == 'trip') {
        return {
          icon: 'suitcase-rolling',
          library: 'fas'
        }
      } else if (type == 'email') {
        return {
          icon: 'envelope',
          library: 'fas'
        }
      } else if (type == 'offer' || type == 'customerInvoice') {
        return {
          icon: 'file-invoice',
          library: 'fas'
        }
      } else {
        return {
          icon: 'exclamation-triangle',
          library: 'fas'
        }
      }
    }

    const displayDate = (date) => {
      let now = moment()
      let tomorrow = moment(clone(now)).add(1, 'days')

      if (now.format('YYYY-MM-DD') == moment(date).format('YYYY-MM-DD')) {
        return i18n.t('today')
      } else if (tomorrow.format('YYYY-MM-DD') == moment(date).format('YYYY-MM-DD')) {
        return i18n.t('tomorrow')
      } else {
        return i18n.t('the') + '  ' + (moment(date).format('LL'))
      }
    }

    const handleLastActivityWorkTimeHover = hovered => {
      isLastActivityWorkTimeHovered.value = hovered
    }

    const handleLastActivityExchangeHover = (hovered) => {
      isLastActivityExchangeHovered.value = hovered
    }

    const handleLastActivityOfferHover = (hovered) => {
      isLastActivityOfferHovered.value = hovered
    }

    const handleLastActivityOrderHover = (hovered) => {
      isLastActivityOrderHovered.value = hovered
    }

    const handleLastActivityInvoiceHover = (hovered) => {
      isLastActivityInvoiceHovered.value = hovered
    }

    const handleLastActivityInterventionHover = (hovered) => {
      isLastActivityInterventionHovered.value = hovered
    }

    const handleLastActivityTripHover = (hovered) => {
      isLastActivityTripHovered.value = hovered
    }

    const handleLastActivityReminderHover = (hovered) => {
      isLastActivityReminderHovered.value = hovered
    }

    const addNewEvent = type => {
      let event = clone(store.getters['event3/getEmptyEvent'])
      event.type = type
      event.workFlo = {
        id: props.workFlo.id
      }
      event.startDate = moment()
      event.endDate = moment().add(15, 'minutes')

      emit('addEvent', event)
    }

    const addNewOffer = () => {
      let offer = clone(store.getters['offer2/getEmptyOffer'])
      offer.workFlo = props.workFlo

      emit('addOffer', offer)
    }

    const addNewReminder = () => {
      let reminder = clone(store.getters['reminder/getEmptyReminder'])
      reminder.workFlo = {
        id: props.workFlo.id
      }
      reminder.completionDate = moment().format('YYYY-MM-DD')

      console.log("0")
      console.log(clone(reminder))
      emit('addReminder', reminder)
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      isHovered,
      isMainContactHovered,
      showMainContactPopover,
      mainContactRef,
      isMainContactCallHovered,
      isMainContactMailHovered,
      isMainContactExchangeHovered,
      isLastActivityHovered,
      showLastActivityPopover,
      lastActivityRef,
      isLastActivityWorkTimeHovered,
      isLastActivityExchangeHovered,
      isLastActivityOfferHovered,
      isLastActivityOrderHovered,
      isLastActivityInvoiceHovered,
      isLastActivityInterventionHovered,
      isLastActivityTripHovered,
      isLastActivityReminderHovered,


      // Computed
      isMainContactReady,
      mainContactPhone,
      mainContactMail,
      mainContactExchange,
      displayLastActivity,
      isLastActivityReady,
      isDeletable,

      // Methods
      handleHover,
      handleMainContactHover,
      handleMainContactCallHover,
      handleMainContactMailHover,
      handleMainContactExchangeHover,
      callMainContact,
      mailMainContact,
      exchangeMainContact,
      handleLastActivityHover,
      handleLastActivityWorkTimeHover,
      handleLastActivityExchangeHover,
      handleLastActivityOfferHover,
      handleLastActivityOrderHover,
      handleLastActivityInvoiceHover,
      handleLastActivityInterventionHover,
      handleLastActivityTripHover,
      handleLastActivityReminderHover,
      addNewEvent,
      addNewOffer,
      addNewReminder,

    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { initials },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>
//.slide-leave-active,
//.slide-enter-active {
//  transition: .5s;
//}
//
//.slide-enter {
//  transform: translateX(100%);
//}
//
//.slide-leave-to {
//  transform: translateX(200%);
//}
</style>