<template>
  <div class="mt-2">

    <h1>{{ capitalize($t('inbound')) }}</h1>

    <b-row>
      <b-col md="6">
        <!--    source -->
        <input-select2
            :model.sync="workFloLocal.sourceIncomingRequest"
            :options="sourceIncomingRequestOptions"
            name="sourceIncomingRequest"
            :placeholder="$t('chooseAnSource')"
            reduce="value"

            @input="resetMarketing"
        />

        <!--    source pressMedia -->
        <input-select2
            v-if="workFloLocal.sourceIncomingRequest == 'press'"
            :key="componentKey"
            :model.sync="workFloLocal.sourcePressMedia"
            :options="sourcePressMediaOptions"
            name="press/media"
            :placeholder="$t('chooseAPressMedia')"
            reduce="value"
        />

        <!--    source social network -->
        <input-select2
            v-if="workFloLocal.sourceIncomingRequest == 'social networking'"
            :key="componentKey"
            :model.sync="workFloLocal.sourceSocialNetwork"
            :options="sourceSocialNetworkOptions"
            name="socialNetwork"
            :placeholder="$t('chooseASocialNetwork')"
            reduce="value"
        />

        <!--    source network -->
        <input-select2
            v-if="workFloLocal.sourceIncomingRequest == 'networks'"
            :key="componentKey"
            :model.sync="workFloLocal.sourceNetwork"
            :options="sourceNetworkOptions"
            name="network"
            :placeholder="$t('chooseANetwork')"
            reduce="value"
        />

        <!--    source event -->
        <input-select2
            v-if="workFloLocal.sourceIncomingRequest == 'event'"
            :key="componentKey"
            :model.sync="workFloLocal.sourceEvent"
            :options="sourceEventOptions"
            name="event"
            :placeholder="$t('chooseAEvent')"
            reduce="value"
        />

      </b-col>

      <b-col md="6">
        <input-field
            name="sourceDetails"
            :model.sync="workFloLocal.sourceDetails"
        />
      </b-col>

    </b-row>

  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { capitalize } from '../../../../../utils/filter'
import InputSelect2 from '../../../../input/Select2.vue'
import i18n from '../../../../../libs/i18n'
import InputField from '../../../../input/Input.vue'

export default {
  components: { InputField, InputSelect2 },
  props: {
    workFlo: {
      type: Object,
      default: () => {}
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const sourceIncomingRequestOptions = ref([
      {
        value: 'internet research SEO',
        _display: capitalize(i18n.t('internetResearchSEO')),
      },
      {
        value: 'internet research GADS',
        _display: capitalize(i18n.t('internetResearchGADS')),
      },
      {
        value: 'internet research MADS',
        _display: capitalize(i18n.t('internetResearchMADS')),
      },
      {
        value: 'social networking',
        _display: capitalize(i18n.t('socialNetworking')),
      },
      {
        value: 'press',
        _display: capitalize(i18n.t('press/media')),
      },
      {
        value: 'networks',
        _display: capitalize(i18n.tc('network',2)),
      },
      {
        value: 'newsletter',
        _display: capitalize(i18n.t('newsletter')),
      },
      {
        value: 'farming',
        _display: capitalize(i18n.tc('farming')),
      },
    ].sort((a, b) => a._display < b._display ? -1 : 1))

    const sourcePressMediaOptions = ref([
      {
        value: 'television',
        _display: capitalize(i18n.t('television')),
      },
      {
        value: 'print media',
        _display: capitalize(i18n.t('print media')),
      },
      {
        value: 'radio',
        _display: capitalize(i18n.t('radio')),
      },
      {
        value: 'other',
        _display: capitalize(i18n.t('other')),
      },
    ].sort((a, b) => a._display < b._display ? -1 : 1))

    const sourceSocialNetworkOptions= ref([
      {
        value: 'linkedin',
        _display: capitalize(i18n.t('linkedin')),
      },
      {
        value: 'instagram',
        _display: capitalize(i18n.t('instagram')),
      },
      {
        value: 'facebook',
        _display: capitalize(i18n.t('facebook')),
      },
      {
        value: 'twitter',
        _display: capitalize(i18n.t('twitter')),
      },
    ].sort((a, b) => a._display < b._display ? -1 : 1))

    const sourceNetworkOptions= ref([
      {
        value: 'reference',
        _display: capitalize(i18n.t('reference')),
      },
      {
        value: 'distributor',
        _display: capitalize(i18n.t('distributor')),
      },
      {
        value: 'businessIntroducer',
        _display: capitalize(i18n.t('businessIntroducer')),
      },
      {
        value: 'prescriber',
        _display: capitalize(i18n.t('prescriber')),
      },
      {
        value: 'personalNetwork',
        _display: capitalize(i18n.t('personalNetwork')),
      },
    ].sort((a, b) => a._display < b._display ? -1 : 1))

    const sourceEventOptions = ref([
      {
        value: 'Préventica',
        _display: 'Préventica',
      },
      {
        value: 'Expoprotection',
        _display: 'Expoprotection',
      },
      {
        value: 'Cap Emploi',
        _display: 'Cap Emploi',
      },
      {
        value: 'Workshop',
        _display: 'Workshop',
      },
    ].sort((a, b) => a._display < b._display ? -1 : 1))

    const componentKey = ref(0)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const workFloLocal = computed({
      get () {
        return props.workFlo
      },
      set (val) {
        emit('update:workFlo', val)
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const resetMarketing = () => {
      workFloLocal.value.sourcePressMedia=null
      workFloLocal.value.sourceSocialNetwork=null
      workFloLocal.value.sourceNetwork=null
      workFloLocal.value.sourceEvent=null

      componentKey.value++
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      sourceIncomingRequestOptions,
      sourcePressMediaOptions,
      sourceSocialNetworkOptions,
      sourceNetworkOptions,
      sourceEventOptions,
      componentKey,

      // Computed
      workFloLocal,

      // Methods
      resetMarketing,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { capitalize },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>