<template>
  <div>
    <h1>{{ capitalize($t('internalManagement')) }}</h1>

    <b-row>

      <!--        Company-->
      <b-col md="12">
        <input-contact
            name="company"
            :with-me="false"
            :contact.sync="workFloLocal.company"
            :is-initial-contact-company="true"
            :is-company-only="true"
            :is-required="true"
            :display-label="false"
            :placeholder="capitalize($t('chooseACompany'))"

            @contactUpdated="updateContacts"
            @change="updateContacts();$emit('companyUpdated')"
        />
      </b-col>

      <!--        Main contact-->
      <b-col md="12">
        <input-select2
            name="mainContact"
            :model.sync="workFloLocal.mainContact"
            :options="listOfContacts"
            :display-label="false"

            @updated="mainContactUpdated"
        />
      </b-col>

      <!--        Other contacts-->
      <b-col md="12">
        <input-select2
            name="otherContacts"
            :model.sync="workFloLocal.otherContacts"
            :options="listOfOthersContacts"
            :placeholder="capitalize($t('chooseOthersContacts'))"
            :multiple="true"
        />
      </b-col>

      <!--    isDemo-->
      <b-col md="12">
        <input-switch
            class="inputSwitch"
            name="isDemo"
            :model.sync="workFloLocal.isDemo"
        />
      </b-col>

      <!--    isPriority-->
<!--      <b-col md="12">-->
<!--        <input-switch-->
<!--            class="inputSwitch"-->
<!--            name="isPriority"-->
<!--            :model.sync="workFloLocal.isPriority"-->
<!--        />-->
<!--      </b-col>-->

    </b-row>

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '../../../../../utils/filter'
import InputContact from '../../../../input/Contact2.vue'
import InputSelect2 from '../../../../input/Select2.vue'
import InputSwitch from '../../../../input/Switch.vue'
import store from '../../../../../store'
import { isObject } from '../../../../../utils/utils'

export default {
  components: { InputSwitch, InputSelect2, InputContact },
  props: {
    workFlo: {
      type: Object,
      default: () => {}
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const listOfContacts = ref([])

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const workFloLocal = computed({
      get () {
        return props.workFlo
      },
      set (val) {
        emit('update:workFlo', val)
      }
    })

    const listOfOthersContacts = computed(() => {
      return (listOfContacts.value.filter(c => c.id != (workFloLocal.value.mainContact ? workFloLocal.value.mainContact.id : null)))
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const updateContacts = () => {
      console.log("updateContacts")
      console.log(workFloLocal.value)
      if (workFloLocal.value && ('company' in workFloLocal.value) && workFloLocal.value.company != undefined) {
        store.dispatch('user2/getAutocompleteUsers', {
          filters: [
            {
              sectionLogicOperator: 'AND',
              sectionFilters: [
                {
                  logicOperator: "AND",
                  field: 'company',
                  operator: 'equal',
                  value: isObject(workFloLocal.value.company) ? workFloLocal.value.company.id : workFloLocal.value.company
                }
              ]
            }
          ]
        }).then(response => {
          console.log(response.results)
          listOfContacts.value = response.results
        })
      }

    }

    const mainContactUpdated = () => {
      workFloLocal.value.otherContacts = []
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    updateContacts()

    return {
      // Components
      capitalize,

      // Data
      listOfContacts,

      // Computed
      workFloLocal,
      listOfOthersContacts,

      // Methods
      updateContacts,
      mainContactUpdated,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>